<template>
  <input
    type="text"
    v-model="text"
    :required="$props.required"
    @keyup="onChange()"
    @keyup.enter="$emit('onEnter')"
    @change="$emit('change')"
    @focus="onFocus"
    @blur="onBlur"
    @click="onFocus"
    :autofocus="autofocus"
  />
</template>
<script>
export default {
  name: "MoneyInput",
  props: ["value", "required", "max", "min", "allowZero", "autofocus"],
  data: () => {
    return {
      text: "",
    };
  },
  mounted() {
    this.text = this.$formatMoney(this.value, false, true);
  },
  watch: {
    value() {
      this.text = this.$formatMoney(this.value, false, true);
    },
  },
  methods: {
    onFocus(e) {
      e.target.select();
      this.$emit("focus");
    },
    onBlur(e) {
      this.$emit("blur");
    },
    focus() {},
    onChange() {
      let number = (this.text || "0").replace(/,/g, "").replace(/\D+/g, "");
      if ((this.max || this.allowZero) && number > this.max) {
        number = this.max;
      }
      if ((this.min || this.allowZero) && number < this.min) {
        number = this.min;
      }
      this.text = this.$formatMoney(number, false, true);
      this.$emit("input", Number(number));
      this.$emit("keyup");
      this.$emit("change");
    },
  },
};
</script>