import gql from "graphql-tag";

export const findBranchOfUser = gql`
  query findBranchOfUser {
    data: findBranchOfUser {
      id
      areas {
        id
        areaName
      }
      companies {
        id
        address
        companyName
        logoUrl
        isOnlinePayment
        companyCode
        taxCode
        moneyOnOnePoint
        phoneNumber
        createUserLimit
        areas {
          id
          areaName
        }
        appConfigs {
          id
          keyName
          keyValues
        }
      }
      branchCode
      branchName
      taxPercent
      phoneNumber
      stocks {
        stockName
        stockCode
        isDefault
        isMaintained
        isNotForSell
        id
        stockId: id
      }
      isStockCompany
      billData
      address
      allowMaxDaysReturn
      allowMaxDaysDeleteOrder
      allowNoBill
      moneyOnOnePoint
      pointToMoney
      appConfigs {
        id
        keyName
        keyValues
      }
      employeeBranches {
        id
        employees {
          id
          address
          fullName
          employeeCode
          email
          isActive
          urlAvatar
        }
      }
    }
  }
`;

export const findStocksBranch = gql`
  query findStocksBranch($branchId: Int!, $where: Json) {
    data: findStocksBranch(branchId: $branchId, where: $where) {
      id
      stockName
      stockCode
      isDefault
      isNotForSell
      isMaintained
      stockId: id
    }
  }
`;

export const findExchangeRatesByBranch = gql`
  query findExchangeRatesByBranch(
    $branchId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findExchangeRatesByBranch(
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on ExchangeRates {
          id
          currency
          denominations
          exchangePrice
        }
      }
      totalCount
    }
  }
`;

export const getPriceLevel = gql`
  query getPriceLevel(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getPriceLevel(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on PriceLevel {
          id
          name
          applies
          description
          isDefault
        }
      }
      totalCount
    }
  }
`;

export const createQRPayment = gql`
  mutation createQRPayment($data: CreateQRInput!, $branchId: Int!) {
    data: createQRPayment(data: $data, branchId: $branchId) {
      msgCode
      extraInfo
      data
      statusCode
    }
  }
`;

export const inquiryQRPaymentStatus = gql`
  query inquiryQRPaymentStatus($invoiceId: Int!, $branchId: Int!) {
    data: inquiryQRPaymentStatus(invoiceId: $invoiceId, branchId: $branchId) {
      msgCode
      extraInfo
      data
      statusCode
    }
  }
`;

export const createPriceLevel = gql`
  mutation createPriceLevel($req: PriceLevelInput!, $companyId: Int!) {
    data: createPriceLevel(req: $req, companyId: $companyId) {
      msgCode
      data {
        id
      }
    }
  }
`;

export const findCardsByBranch = gql`
  query findCardsByBranch($branchId: Int!, $where: Json) {
    data: findCardsByBranch(branchId: $branchId, where: $where) {
      id
      cardNumber
    }
  }
`;

export const findEmployee = gql`
  query findEmployee(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findEmployee(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on Employees {
          id
          isActive
          email
          urlAvatar
          employeeCode
          fullName
          phoneNumber
          birthday
          totalFund
          totalTransfer
          totalReceiver
          address
          leader {
            id
            fullName
          }
          employeeBranches {
            id
            branches {
              id
              branchName
              branchName
            }
          }
          description
          departmentEmployees {
            id
            department {
              id
              code
              name
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const getBrands = gql`
  query getBrands(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getBrands(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on Brands {
          id
          name
          description
          country
          images
          inActive
        }
      }
      totalCount
    }
  }
`;

export const createBrand = gql`
  mutation createBrand($req: BrandInput!, $companyId: Int!) {
    data: createBrand(req: $req, companyId: $companyId) {
      msgCode
      data {
        id
      }
    }
  }
`;

export const findEmployeeBranch = gql`
  query findEmployeeBranch(
    $branchId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findEmployeeBranch(
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on EmployeeBranches {
          id
          employees {
            id
            employeeCode
            fullName
          }
        }
      }
      totalCount
    }
  }
`;

export const updateBranches = gql`
  mutation updateBranches($data: BranchesInput!, $companyId: Int!) {
    data: updateBranches(data: $data, companyId: $companyId) {
      msgCode
      data {
        id
      }
    }
  }
`;

export const updateCustomerForAdmin = gql`
  mutation updateCustomerForAdmin($data: [CustomerRequest]!, $companyId: Int!) {
    data: updateCustomerForAdmin(data: $data, companyId: $companyId) {
      msgCode
      data {
        id
      }
    }
  }
`;
export const createExchangeRates = gql`
  mutation createExchangeRates($data: ExchangeRatesInput!) {
    data: createExchangeRates(data: $data) {
      msgCode
      data {
        id
      }
    }
  }
`;

export const updateExchangeRates = gql`
  mutation updateExchangeRates($data: ExchangeRatesInput!) {
    data: updateExchangeRates(data: $data) {
      msgCode
      data {
        id
      }
    }
  }
`;

export const createStockBranch = gql`
  mutation createStockBranch($data: StockInput!) {
    data: createStockBranch(data: $data) {
      msgCode
      data {
        id
      }
    }
  }
`;

const customerObjSummary = `
  id
  rankId
  rankName
  customerCode
  customerName
  phoneNumber
  point
  totalPoint
  note
  email
  genderType
  taxCode
  birthday
  debtLimit
  maxDaysDebt
  overdueDebt
  totalDebt
  urlAvatar
  totalCredit
  contact
  address
  city
  district
  ward
  lat
  lng
  
`;
const customerObj = `
${customerObjSummary}

staffInCharge{
  id
  employeeCode
  fullName
  email
}

userCustomers{
  id
  userName
}

customerGroup {
  id
  priceLevel{
    id
    name
    isDefault
  }
  groupName
  discountPercent
}
customerCompany{
  id
  code
  urlLogo
  shortName
  legalName
  phoneNumber
  email
  address
  taxNumber
  description
  representativeName
}
`;

export const updateCustomerInfo = gql`
  mutation updateCustomer($customerRequest: CustomerRequest!, $branchId: Int!) {
    data: updateCustomer(reqData: $customerRequest, branchId: $branchId) {
      msgCode
      data {
        id
        ... on Customers {
          ${customerObj}
        }
      }
    }
  }
`;

export const createCustomer = gql`
  mutation createCustomer($customerRequest: CustomerRequest!, $branchId: Int!) {
    data: createCustomer(reqData: $customerRequest, branchId: $branchId) {
      msgCode
      data {
        id
        ... on Customers {
          ${customerObj}
        }
      }
    }
  }
`;

export const findCustomerByCode = gql`
  query findCustomerByCode($code: String!, $branchId: Int!) {
    data: findCustomerByCode(code: $code, branchId: $branchId) {
      id
      ... on Customers {
        ${customerObj}

        
      }
    }
  }
`;

export const getCustomerGroup = gql`
  query getCustomerGroup(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getCustomerGroup(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on CustomerGroup {
          groupName
          discountPercent
          description
          priceLevel {
            id
            name
            description
            isDefault
          }
        }
      }
    }
  }
`;

export const findCustomers = gql`
  query findCustomers(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findCustomers(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on Customers {
          ${customerObj}
        }
      }
    }
  }
`;
export const createUser = gql`
  mutation createUser(
    $userName: String!
    $password: String!
    $branchId: Int!
    $companyId: Int!
  ) {
    data: createUser(
      userName: $userName
      password: $password
      branchId: $branchId
      companyId: $companyId
    ) {
      statusCode
      msgCode
      data {
        id
      }
    }
  }
`;

export const createProductSupplier = gql`
  mutation createProductSupplier(
    $data: [ProductSupplierInput]!
    $companyId: Int!
  ) {
    data: createProductSupplier(data: $data, companyId: $companyId) {
      statusCode
      msgCode
      data {
        id
      }
    }
  }
`;

export const updateProductSupplier = gql`
  mutation updateProductSupplier(
    $data: ProductSupplierInput!
    $companyId: Int!
    $id: Int!
  ) {
    data: updateProductSupplier(data: $data, companyId: $companyId, id: $id) {
      statusCode
      msgCode
      data {
        id
      }
    }
  }
`;

export const createCustomerGroup = gql`
  mutation createCustomerGroup($data: CustomerGroupInput!, $companyId: Int!) {
    data: createCustomerGroup(data: $data, companyId: $companyId) {
      statusCode
      msgCode
      data {
        id
      }
    }
  }
`;

export const updateCustomerGroup = gql`
  mutation updateCustomerGroup(
    $id: Int!
    $data: CustomerGroupInput!
    $companyId: Int!
  ) {
    data: updateCustomerGroup(id: $id, data: $data, companyId: $companyId) {
      statusCode
      msgCode
      data {
        id
      }
    }
  }
`;

export const createProductVariant = gql`
  mutation createProductVariant($data: ProductVariantInput!, $companyId: Int!) {
    data: createProductVariant(data: $data, companyId: $companyId) {
      statusCode
      msgCode
      data {
        id
      }
    }
  }
`;

export const updateProductVariant = gql`
  mutation updateProductVariant(
    $id: Int!
    $data: ProductVariantInput!
    $companyId: Int!
  ) {
    data: updateProductVariant(id: $id, data: $data, companyId: $companyId) {
      statusCode
      msgCode
      data {
        id
      }
    }
  }
`;

const productQuerySummary = `
  id
  name
  image
  secondaryName
  code
  saleOver
  isBundle
  noCheckWarehouse
  upc
  priceDefault
  unit{
    id
    name
    types
  }
  code
  isSelling
  saleOver
  productType
  isBOM
  processBOM

`;

const productBOM = `
productsBOMS{
  percentDepreciation
  id
  exchange
  productUnits{
    id
    name
  }
  bomDetail{
    id
    name
    code
    image
    
    productCostPrices{
      id
      costPrice
    }
    productPrices{
      id
      price
      priceLevel{
        id
        name
        isDefault
      }
      branches {
        id
        branchCode
        branchName
      }
    }
    unit{
      id
      name
    }
    exchangeUnits{
      id
      productUnits{
        id
        name
      }
      exchange
    }
  }
  quantity
  description
}
`;
const productQuery = `
  ${productQuerySummary}
  productStatuses {
    id
    quantity
    branches {
      id
      branchCode
      branchName
    }
  }
  productVariant{
    id
    code
    name
  }
  productSales{
    id
    endDate
    fromDate
    cashSaleOff
    percentSaleOff
    branches{
      id
    }
  }
  productBranchConfigs{
    id
    minQuantity
    maxQuantity
    branches {
      id
      branchCode
      branchName
    }
  }
  productCostPrices{
    id
    costPrice
  }
  productPrices{
    id
    price
    priceLevel{
      id
      name
      isDefault
    }
    branches {
      id
      branchCode
      branchName
    }
  }

  
  smallGroups{
    id
  }
  manufactureCosts{
    id
    costKey
    costValue
  }
  description
  productProperties{
    id
    keyName
    keyValues
  }
  productSuppliers{
    id
    price
    suppliers{
      id
      supplierName
      supplierCode
    }
  }

  exchangeUnits{
    id
    productUnits{
      id
      name
    }
    exchange
  }
  productDetails{
    id
    quantity
    detail{
      id
      name
      code
      saleOver
      isBundle
      noCheckWarehouse
      upc
      priceDefault
      productCostPrices{
        id
        costPrice
      }
      productPrices{
        id
        price
        priceLevel{
          id
          name
          isDefault
        }
        branches {
          id
          branchCode
          branchName
        }
      }
    
      unit{
        id
        name
        types
      }
    }
  }
`;

export const getProductVariantDetail = gql`
  query getProductVariant(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getProductVariant(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on ProductVariant {
          id
          secondaryName
          code
          name
          products {
            id
            name
            code
            productPrices {
              id
              priceLevel {
                id
                name
                isDefault
              }
              price
              branches {
                id
                branchCode
                branchName
              }
            }
            productProperties {
              id
              keyName
              keyValues
            }
          }
          image
          productUnits {
            id
            name
          }
          smallGroups {
            id
            code
            name
            urlImage
          }
          productType
          priceMin
          priceMax
          rate
          description
          nameSeo
          slug
          descriptionSeo
          selled
          viewed
          tag
          brands {
            id
            name
            images
            country
            description
          }
          feedbacks {
            id
            title
            content
            rate
            status
            images
            customers {
              id
              customerName
            }
          }
          variantDetails {
            id
            variantKey
            variantValues
          }
        }
      }
      totalCount
    }
  }
`;
export const getProductVariant = gql`
  query getProductVariant(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getProductVariant(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on ProductVariant {
          id
          code
          name
          image
          productUnits {
            id
            name
          }
          smallGroups {
            id
            code
            name
            urlImage
          }
          productType
          priceMin
          priceMax
          rate
          description
          nameSeo
          slug
          descriptionSeo
          variantDetails {
            id
            variantKey
            variantValues
          }
        }
      }
      totalCount
    }
  }
`;

export const findProductsHaveBom = gql`
query findProducts($branchId: Int!, $where: Json, $orderBy: String, $limit: Int, $skip: Int){
  data:findProducts(branchId: $branchId,where: $where, orderBy: $orderBy, limit: $limit, skip: $skip ){
    list{
      id
      ... on Products{
       ${productQuery}
       ${productBOM}
      }
    }
    totalCount
  }
}
`;

export const findProducts = gql`
query findProducts($branchId: Int!, $where: Json, $orderBy: String, $limit: Int, $skip: Int){
  data:findProducts(branchId: $branchId,where: $where, orderBy: $orderBy, limit: $limit, skip: $skip ){
    list{
      id
      ... on Products{
       ${productQuery}
      }
    }
    totalCount
  }
}
`;

export const findProductList = gql`
  query findProducts(
    $branchId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findProducts(
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        ... on Products {
          id
          code
          image
          name
          upc
          secondaryName
          isBOM
          isBundle
          description
          productVariant {
            id
            code
            name
            image
            description
          }
          unit {
            id
            name
            types
          }
          exchangeUnits {
            id
            productUnits {
              id
              name
            }
            exchange
          }
          productType
          productDetails {
            id
          }
          isSelling
          noCheckWarehouse
          upc
          priceDefault
          saleOver
          productCostPrices {
            id
            costPrice
          }
          productPrices {
            id
            price
            priceLevel {
              id
              name
              isDefault
            }
            branches {
              id
              branchCode
              branchName
            }
          }
          productBranchConfigs {
            id
            minQuantity
            maxQuantity
            branches {
              id
              branchCode
              branchName
            }
          }
          smallGroups {
            id
            code
            urlImage
          }
          productSuppliers {
            id
            price
            suppliers {
              id
              supplierName
              supplierCode
            }
          }
        }
      }
      totalCount
    }
  }
`;
export const findProductsAvailable = gql`
query findProducts($branchId: Int!, $where: Json, $orderBy: String, $limit: Int, $skip: Int){
  data:findProducts(branchId: $branchId,where: $where, orderBy: $orderBy, limit: $limit, skip: $skip ){
    list{
      id
      ... on Products{
        ${productQuery}
        
      }
    }
    totalCount
  }
}
`;

export const findDiscountByCode = gql`
  query findDiscountByCode($branchId: Int!, $discountCode: String!) {
    data: findDiscountByCode(branchId: $branchId, discountCode: $discountCode) {
      data {
        id
        ... on Discounts {
          id
          code
          fromDate
          discountTypes
          discountForms
          endDate
          manyUse
          useNumber
          value
        }
      }
    }
  }
`;
export const findMiddleGroup = gql`
  query getMiddleGroups($companyId: Int!, $limit: Int) {
    data: findMiddleGroups(companyId: $companyId, limit: $limit) {
      list {
        id
        ... on MiddleGroups {
          id
          name
          id
          code
          urlImage
          smallGroups {
            id
            name
            code
            urlImage
          }
        }
      }
    }
  }
`;
const invoiceInfoSummary = `
  id 
  isB2B
  code 
  title
  channels
  orderStatus
  paymentStatus
  orderType
  total
  cashTotal
  cardTotal
  cashDiscount
  cashDiscountType
  cashDiscountPercent
  voucherDiscount
  couponDiscount
  numberPointConvert
  moneyPointConvert
  discountCustomerTotal
  surchargeProductTotal
  membershipDiscountMoney
  membershipDiscountPercent
  grandTotalBeforeTax
  percentTax
  taxMoney
  grandTotal
  remainPayment
  invoiceDate
  beforeSaleOffTotal
  saleOffTotal
  locationId
  locationName
  costTotal
  areaId
  areaName
  branchId
  isGetPointMember
  branchName
  companyId
  companyName
  invoiceCreateByUserName
  invoiceCreateById
  createdBy
  createdAt
  sInvoicePath
  totalDiscount
  totalSurcharge
  personInCharge{
    id
    employeeCode
    fullName
  }
  totalDebt
  remainDebt
  paidMoney
  refInvoice {
    id
    code
  }
  customers{
    ${customerObj}
  }
  subStatus
  description
  surcharge
  priceLevel{
    id
    name
    isDefault
  }
  cart{
    id
    code
  }
  employeeSales{
    id
    employeeId
    employeeName
    moneyTotal
    percentSale
    moneySale
  }

  invoiceInitials{
    id
    discountCustomer
    discountCustomerPercent
    products{
      id
      code
      name
      secondaryName
      isBundle
      isBOM
      saleOver
      noCheckWarehouse
      unit{
        id
        name
      }
    }
    productQuantity
    productPrice
    costPrice
    total
    costTotal
    cashSaleOff
    totalPayment
    bundleId
    surcharge
    surchargeType
    saleOffType
    saleOffValue
    surchargeValue
    isGift
    percentTax
    taxMoney

    totalAfterTax
    totalBeforeTax
    orderSaleOff
    orderSurcharge
  }
  invoiceProcessSteps{
    id
    code
    stepName
    processDate
  }
`;
const invoiceInfo = `
  ${invoiceInfoSummary}
  employeeSaleProcesses{
    id
    invoiceProcessSteps {
      id
      code
      stepName
    }
    employees{
      id
      fullName
    }
    employeeBranchSales {
      id
      employeeId
      percentSale
    }
    money
    receiveDate
    saleDate
    isReceiver
  }
  surcharges{
    id
    surchargeType
    amount
    description
    invoiceProcessSteps{
      id
      stepName
    }
  }
  invoiceProcessSteps{
    id
    code
    stepName
    processDate
    doneStock
    isDebt
    processStepInits{
      id
      productId
      productQuantity
      isGift
      stocks
    }
    processStatus
    description
    subStatus
    total
    costTotal
    beforeSaleOffTotal
    saleOffTotal
    cashDiscount
    voucherDiscount
    couponDiscount
    moneyPointConvert
    membershipDiscountMoney
    grandTotalBeforeTax
    taxMoney
    grandTotal
    surchargeProductTotal
    surcharge
    discountCustomerTotal

    surcharges{
      id
      surchargeType
      amount
      description
    }
  }
  
  invoiceDetails{
    id
    discountCustomer
    discountCustomerPercent
    total
    costPrice
    costTotal
    totalPayment
    cashSaleOff
    productId
    productType
    productId
    productCode
    productPrice
    productQuantity
    productProperties
    productName
    productImg
    unitName
    surcharge
    bundleId
    surchargeType
    saleOffType
    saleOffValue
    isGift
    surchargeValue
    smallGroup
    middleGroup
    largeGroup
    percentTax
    taxMoney
    
    totalAfterTax
    totalBeforeTax

    orderSaleOff
    orderSurcharge

    stocks:invoiceDetailStocks{
      id
      stockId
      stockName
      quantity
      expiryDate
      batchName
      manufacturingDate
    }
    invoiceProcessSteps{
      id
      code
    }
  }
  invoiceDiscounts{
    id
    discountForm
    discountCode
    discountType
    discountValue
    discountMoney
  }
  invoiceCashPayments{
    id
    totalCash
    totalPayment
    exchangePrice
    currencyUnit
    isExtra
    isDebit
    paymentTime
    description
  }
  invoiceCardPayments{
    id
    cardType
    cardNumber
    totalPayment
    isExtra
    description
    paymentTime
  }
  extraMoney
 

`;
export const findInvoices = gql`
query findInvoicesByBranch($branchId: Int!, $where: Json, $orderBy: String, $limit: Int,  $skip: Int) {
  data:findInvoicesByBranch(branchId: $branchId, where: $where,orderBy : $orderBy, limit: $limit, skip: $skip){
    totalCount
    list{
      id
      ... on Invoices{
        ${invoiceInfo}
      }
    }
  }
}
`;

export const findInvoiceInfo = gql`
query findInvoiceInfo($branchId: Int!, $data: String!, $where: Json) {
  data:findInvoiceInfo(branchId: $branchId, data: $data, where: $where){
    ${invoiceInfo}
  }
}
`;

export const findInvoicesSummary = gql`
query findInvoicesByBranch($branchId: Int!, $where: Json, $orderBy: String, $limit: Int,  $skip: Int) {
  data:findInvoicesByBranch(branchId: $branchId, where: $where,orderBy : $orderBy, limit: $limit, skip: $skip){
    totalCount
    list{
      id
      ... on Invoices{
        ${invoiceInfoSummary}
      }
    }
  }
}
`;
export const findInvoicesBranchForAdmin = gql`
query findInvoicesByBranchForAdmin($branchIds: [Int]!, $where: Json, $orderBy: String, $limit: Int, $skip: Int) {
  data: findInvoicesByBranchForAdmin(branchIds: $branchIds, where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
    totalCount
    list{
      id
      ... on Invoices{
        ${invoiceInfoSummary}

      }
    }
  }
}
`;

export const findInvoicesBranchForAdminDetail = gql`
query findInvoicesByBranchForAdmin($branchIds: [Int]!, $where: Json, $orderBy: String, $limit: Int, $skip: Int) {
  data: findInvoicesByBranchForAdmin(branchIds: $branchIds, where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
    totalCount
    list{
      id
      ... on Invoices{
        ${invoiceInfo}
      }
    }
  }
}
`;

export const findInvoicesProcessing = gql`
query findInvoicesProcessing($branchId: Int!, $where: Json, $orderBy: String, $limit: Int,  $skip: Int) {
  data:findInvoicesProcessing(branchId: $branchId, where: $where,orderBy : $orderBy, limit: $limit, skip: $skip){
    totalCount
    list{
      id
      ... on Invoices{
        ${invoiceInfo}
      }
    }
  }
}
`;

export const findInvoiceAreasByBranch = gql`
query findInvoiceAreasByBranch($branchId: Int!, $where: Json, $orderBy: String, $limit: Int, $skip: Int){
  data:findInvoiceAreasByBranch(branchId: $branchId,where: $where, orderBy: $orderBy, limit: $limit, skip: $skip){
    id
    ... on InvoiceAreas{
      id
      name
      invoiceLocations{
        id
        name
        openSeat{
          ${invoiceInfo}
        }
      }
    }
  }
}

`;

export const invoiceSummaries = gql`
  query invoiceSummaries($branchIds: [Int]!, $where: Json) {
    data: invoiceSummaries(branchIds: $branchIds, where: $where) {
      total
      taxMoney
      grandTotal
      discount
      remainTotal
      surcharge
      discountCustomerTotal
      surchargeProductTotal
      costTotal
      debtTotal
      remainDebt
      remainPayment
    }
  }
`;

export const productInventoryCheck = gql`
  query productInventoryCheck($branchId: Int!, $prodId: Int!) {
    data: productInventoryCheck(branchId: $branchId, prodId: $prodId) {
      id
      prdId
      prdCode
      prdName
      prdImg
      prdProperties
      prdDetails
      supplierId
      supplierName
      availableQty: quantity
      stockId
      stockName
      branchName
      companyId
      companyName
      refId
      type
      inventoryDate
      description
    }
  }
`;

export const productInventoryCheckByStock = gql`
  query productInventoryCheckByStock(
    $branchId: Int!
    $prodId: Int!
    $stockId: Int!
  ) {
    data: productInventoryCheckByStock(
      branchId: $branchId
      prodId: $prodId
      stockId: $stockId
    ) {
      id
      prdId
      prdCode
      prdName
      prdImg
      prdProperties
      prdDetails
      supplierId
      availableQty: quantity
      stockId
      stockName
      companyId
      price
    }
  }
`;

export const findOrders = gql`
query findOrders( $where: Json, $orderBy: String, $limit: Int,  $skip: Int) {
  data:findPage( where: $where,orderBy : $orderBy, limit: $limit, skip: $skip){
    totalCount
    list{
      id
      ... on Invoices{
        ${invoiceInfo}
      }
    }
  }
}
`;
export const findOrder = gql`
query findOrder( $invoiceId: Int!, $branchId: Int!) {
  data:getInvoiceById( invoiceId: $invoiceId,branchId : $branchId){
    data{
      id
      ... on Invoices{
        ${invoiceInfo}

      }
    }
  }
}
`;

export const findCardTypes = gql`
  query findCardTypes($branchId: Int!, $where: Json) {
    list: findCardTypes(branchId: $branchId, where: $where) {
      id
      typeName
      isDirect
    }
  }
`;

export const findUsers = gql`
  query findUsers($where: Json, $orderBy: String, $limit: Int, $skip: Int) {
    data: findPage(
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on Users {
          id
          userName
          isEmployee
          employees {
            id
          }
          userBranches {
            id
            isDefault
            branches {
              id
              branchName
              companies {
                id
                companyName
              }
            }
            userBranchRoles {
              id
              roles {
                id
                roleName
              }
            }
          }
        }
      }
    }
  }
`;

export const findRoles = gql`
  query findRoles(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findRoles(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on Roles {
          id
          roleName
        }
      }
    }
  }
`;

export const findRolesByAdmin = gql`
  query findRolesByAdmin(
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findPage(
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on Roles {
          id
          roleName
        }
      }
    }
  }
`;

export const findRoleDetails = gql`
  query findRoleDetails($companyId: Int!, $where: Json, $roleId: Int!) {
    data: findRoleDetails(
      companyId: $companyId
      where: $where
      roleId: $roleId
    ) {
      id
      isUsed
      pageCode
    }
  }
`;
export const findRoleDetailsAdmin = gql`
  query findRoleDetailsAdmin(
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findPage(
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        ... on RoleDetails {
          id
          isUsed
          pageCode
        }
      }
    }
  }
`;

export const findCompanies = gql`
  query findCompanies(
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
    $includeInactive: Boolean
  ) {
    data: findPage(
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
      includeInactive: $includeInactive
    ) {
      totalCount
      list {
        id
        ... on Companies {
          id
          companyName
          companyCode
          address
          taxCode
          isOnlinePayment
          logoUrl
          moneyOnOnePoint
          inActive
          phoneNumber
          createUserLimit
        }
      }
    }
  }
`;

export const findMembershipRank = gql`
  query findMembershipRank(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findMembershipRank(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on MembershipRank {
          id
          rankName
          pointFrom
          pointEnd
          saleOffPercent
        }
      }
    }
  }
`;

export const findAreas = gql`
  query findAreas($where: Json, $orderBy: String, $limit: Int, $skip: Int) {
    data: findPage(
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on Areas {
          id
          areaName
          companies {
            id
            companyName
          }
        }
      }
    }
  }
`;

const branchInfo = `
id
branchName
pointToMoney
moneyOnOnePoint
branchCode
address
allowMaxDaysDeleteOrder
employeeBranches{
  id
  employees{
    id
    fullName
    employeeCode
  }
}
companies{
  id
  companyName
}
areas{
  id
  areaName
}
stocks{
  id
  stockCode
  stockName
  isNotForSell
  isDefault
  isMaintained
  description
  stockId:id
}
cards{
  id 
  cardNumber
}
cardTypes{
  id
  typeName
  isDirect
}
invoiceAreas{
  id
  name
  invoiceLocations{
    id
    name
  }
}
appConfigs{
  id
  keyName
  keyValues
}
billData
taxPercent
billData
phoneNumber
allowMaxDaysReturn
allowNoBill
isStockCompany`;
export const findBranches = gql`
query findBranches( $where: Json, $orderBy: String, $limit: Int,  $skip: Int) {
  data:findPage( where: $where,orderBy : $orderBy, limit: $limit, skip: $skip){
    totalCount
    list{
      id
      ... on Branches{
        ${branchInfo}
      }
    }
  }
}
`;

export const findBranchByCompany = gql`
query getBranchByCompany( $companyId: Int!) {
  data:getBranchByCompany( companyId: $companyId){
        ${branchInfo}
  }
}
`;

export const findUserBranches = gql`
query getUserBranch( $where: Json ) {
  list:getUserBranch( where: $where){
    id
    branches{
      ${branchInfo}
    }
    userBranchRoles{
      id
      roles{
        id
        roleName
      }
    }
  }
}
`;
export const findUserByCompany = gql`
  query findUserByCompany(
    $where: Json
    $companyId: Int!
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findUserByCompany(
      where: $where
      companyId: $companyId
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        ... on Users {
          id
          userName
          employees {
            id
            fullName
          }
          userBranches {
            id
            branches {
              id
              branchName
              allowMaxDaysDeleteOrder
              companies {
                id
              }
            }
            userBranchRoles {
              id
              roles {
                id
                roleName
              }
            }
          }
        }
      }
    }
  }
`;

export const findLargeGroups = gql`
  query findLargeGroups(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findLargeGroups(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on LargeGroups {
          id
          code
          name
          urlImage
        }
      }
    }
  }
`;

export const findCategoriesProductTree = gql`
  query findLargeGroups(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findLargeGroups(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on LargeGroups {
          id
          code
          name
          urlImage
          middles: middleGroups {
            id
            code
            name
            urlImage
            smalls: smallGroups {
              id
              code
              name
              applies
              urlImage
              products{
                ${productQuerySummary}
              }
              
            }
          }
        }
      }
    }
  }
`;

export const findCategoriesTree = gql`
  query findLargeGroups(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findLargeGroups(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on LargeGroups {
          id
          code
          name
          urlImage
          middles: middleGroups {
            id
            code
            name
            urlImage
            smalls: smallGroups {
              id
              code
              name
              applies
              urlImage
              branches: branchesSmallGroups {
                id
                branches {
                  id
                  branchName
                  branchCode
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const findMiddleGroups = gql`
  query findMiddleGroups(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findMiddleGroups(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on MiddleGroups {
          id
          code
          name
        }
      }
    }
  }
`;

export const findSmallGroups = gql`
  query findSmallGroups(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findSmallGroups(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on SmallGroups {
          id
          code
          name
          urlImage
          applies
          branches: branchesSmallGroups {
            id
            branches {
              id
            }
          }
          middleGroups {
            id
            code
            urlImage
            name
          }
        }
      }
    }
  }
`;
export const findSupliers = gql`
  query findSupliers($where: Json, $companyId: Int!) {
    data: findSupplierByCompany(companyId: $companyId, where: $where) {
      id
      supplierCode
      supplierName
      address
      phoneNumber
      isInternal
      process
      note
      email
      debtLimit
      maxDaysDebt
      taxNumber
      totalDebt
      totalCredit
      overdueDebt
      supplierBranches {
        id
        branches {
          id
          branchName
          branchCode
        }
      }
    }
  }
`;

export const findSmartInventory = gql`
  query findSmartInventory(
    $stockIds: [Int]
    $batchNames: [String]
    $productIds: [Int]
    $branchId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findSmartInventory(
      stockIds: $stockIds
      batchNames: $batchNames
      productIds: $productIds
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
      branchId: $branchId
    ) {
      totalCount
      list {
        id
        ... on Inventory {
          id
          prdId
          prdCode
          prdName
          availableQty: quantity
          inventoryDate
          productUnit
          price
          stockId
          stockName
          costPrice
          batchName
          expiryDate
          manufacturingDate
        }
      }
    }
  }
`;

export const findInventories = gql`
  query findInventories(
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findPage(
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on Inventory {
          id
          prdId
          prdImg
          prdCode
          prdName
          quantity
          inventoryDate
          stockId
        }
      }
    }
  }
`;

export const findWarehouse = gql`
  query findWarehouse($where: Json, $orderBy: String, $limit: Int, $skip: Int) {
    data: findPage(
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on Warehouse {
          id
          prdId
          prdImg
          prdCode
          prdName
          quantity
          type
          description
          date
        }
      }
    }
  }
`;
export const findDiscounts = gql`
  query findDiscountsCompany(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findDiscountsCompany(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on Discounts {
          id
          discountForms
          discountTypes
          code
          value
          fromDate
          endDate
          manyUse
          useNumber
          description
        }
      }
    }
  }
`;

export const getProductSupplier = gql`
  query getProductSupplier(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getProductSupplier(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on ProductSupplier {
          id
          products {
            id
            code
            name
            isSelling
          }
          price
          description
        }
      }
    }
  }
`;

export const findProductUnits = gql`
  query findProductUnits($where: Json) {
    data: findProductUnit(where: $where) {
      id
      name
      types
      transferUnits {
        id
        transferName
        transferValue
      }
    }
  }
`;
export const findUserByBranch = gql`
  query findUserByBranch($branchId: Int!) {
    data: findUserByBranch(branchId: $branchId) {
      id
      userName
    }
  }
`;
export const findUser = gql`
  query findUser($id: ID) {
    data: findOne(id: $id) {
      id
      ... on Users {
        id
        userName
        isEmployee
        employees {
          id
        }
        userBranches {
          id
          branches {
            id
            branchName
          }
          isDefault
          userBranchRoles {
            id
            roles {
              id
              roleName
            }
          }
        }
      }
    }
  }
`;

export const findCurrentUser = gql`
  query getCurrentUserBranches($where: Json) {
    list: getCurrentUserBranches(where: $where) {
      id
      isDefault
      users {
        id
        userName
        isSupperAdmin
        employees {
          id
          isActive
          email
          urlAvatar
          employeeCode
          fullName
          phoneNumber
          birthday
          totalFund
          totalTransfer
          totalReceiver
          address
          departmentEmployees {
            id
            department {
              id
              code
              name
            }
          }
        }
      }
      userBranchRoles {
        id
        roles {
          id
          roleName
          roleDetails {
            id
            isUsed
            pageCode
          }
        }
      }
      branches {
        id
        branchCode
        phoneNumber
        branchName
        stocks {
          id
          stockName
        }
        allowMaxDaysDeleteOrder
        areas {
          id
          areaName
        }
        companies {
          id
          companyName
          logoUrl
          phoneNumber
        }
      }
    }
  }
`;

export const deleteExchangeRate = gql`
  mutation deleteExchangeRate($branchId: Int!, $id: Int!) {
    data: deleteExchangeRate(branchId: $branchId, id: $id) {
      data {
        id
      }
      message
    }
  }
`;

export const saveOrder = gql`
mutation saveOrder($data: InvoiceInput!, $incomeData: [IncomeExpenseInput]!) {
    data: saveOrder(data: $data, incomeData: $incomeData) {
      data{
        id
        ... on Invoices{
          ${invoiceInfo}
        }
      }
      message
    }
  }
  `;
export const updateProductPrices = gql`
  mutation updateProductPrices(
    $productId: Int!
    $companyId: Int!
    $priceReqs: [ProductPricesInput]!
  ) {
    data: updateProductPrices(
      productId: $productId
      companyId: $companyId
      priceReqs: $priceReqs
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const updateProductCostPrice = gql`
  mutation updateProductCostPrice(
    $data: [PrdCostPriceInput]!
    $companyId: Int!
  ) {
    data: updateProductCostPrice(data: $data, companyId: $companyId) {
      data {
        id
      }
      message
    }
  }
`;

export const updateProductSale = gql`
  mutation updateProductSale($companyId: Int!, $data: [ProductSaleInput]!) {
    data: updateProductSale(companyId: $companyId, data: $data) {
      data {
        id
      }
      message
    }
  }
`;

export const updateCompany = gql`
  mutation updateCompany($data: CompanyInput!) {
    data: updateCompany(data: $data) {
      data {
        id
      }
      message
    }
  }
`;

export const createInvoiceArea = gql`
  mutation createInvoiceArea($branchId: Int!, $name: String!) {
    data: createInvoiceArea(branchId: $branchId, name: $name) {
      data {
        id
      }
      message
    }
  }
`;

export const updateInvoiceArea = gql`
  mutation updateInvoiceArea($branchId: Int!, $name: String!, $id: Int!) {
    data: updateInvoiceArea(branchId: $branchId, name: $name, id: $id) {
      data {
        id
      }
      message
    }
  }
`;

export const deleteInvoiceArea = gql`
  mutation deleteInvoiceArea($branchId: Int!, $id: Int!) {
    data: deleteInvoiceArea(branchId: $branchId, id: $id) {
      data {
        id
      }
      message
    }
  }
`;

export const createInvoiceLocation = gql`
  mutation createInvoiceLocation(
    $branchId: Int!
    $name: String!
    $invoiceAreaId: Int!
  ) {
    data: createInvoiceLocation(
      branchId: $branchId
      name: $name
      invoiceAreaId: $invoiceAreaId
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const updateInvoiceLocation = gql`
  mutation updateInvoiceLocation(
    $branchId: Int!
    $name: String
    $invoiceAreaId: Int
    $invoiceLocationId: Int!
  ) {
    data: updateInvoiceLocation(
      branchId: $branchId
      name: $name
      invoiceAreaId: $invoiceAreaId
      invoiceLocationId: $invoiceLocationId
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const deleteInvoiceLocation = gql`
  mutation deleteInvoiceLocation($branchId: Int!, $invoiceLocationId: Int!) {
    data: deleteInvoiceLocation(
      branchId: $branchId
      invoiceLocationId: $invoiceLocationId
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const createProduct = gql`
  mutation createProduct($data: ProductInput!) {
    data: createProduct(data: $data) {
      data {
        id
        ... on Products {
          id
          secondaryName
          code
        }
      }
      message
    }
  }
`;

export const createMultiProduct = gql`
  mutation createMultiProduct($data: [ProductInput]!) {
    data: createMultiProduct(data: $data) {
      data {
        id
        ... on Products {
          id
        }
      }
      message
    }
  }
`;

export const updateProduct = gql`
  mutation updateProduct($data: ProductInput!) {
    data: updateProduct(data: $data) {
      statusCode
      msgCode
      message
    }
  }
`;

// export const completeOrder = gql`
// mutation completeOrder($data: [InvoiceInputUpdate]!, $incomeData: [IncomeExpenseInput]!) {
//     data: completeOrder(data: $data, incomeData: $incomeData) {
//       data{
//         id
//         ... on Invoices{
//           ${invoiceInfo}
//         }
//       }
//       message
//     }
//   }
//   `;

export const cancelOrder = gql`
  mutation cancelOrder($invoiceId: Int!, $branchId: Int!) {
    data: cancelOrder(invoiceId: $invoiceId, branchId: $branchId) {
      data {
        id
      }
      message
    }
  }
`;
export const deleteOrder = gql`
  mutation deleteOrder(
    $invoiceId: Int!
    $branchId: Int!
    $incomeData: [IncomeExpenseInput]!
  ) {
    data: deleteOrder(
      invoiceId: $invoiceId
      branchId: $branchId
      incomeData: $incomeData
    ) {
      data {
        id
      }
      message
    }
  }
`;
export const updateStockDefault = gql`
  mutation updateStockDefault($stockId: Int!, $branchId: Int!) {
    data: updateStockDefault(stockId: $stockId, branchId: $branchId) {
      data {
        id
      }
      message
    }
  }
`;

export const updateStock = gql`
  mutation updateStock($id: Int!, $data: StockInput!) {
    data: updateStock(id: $id, data: $data) {
      data {
        id
      }
      message
    }
  }
`;

export const updateUserBranchDefault = gql`
  mutation updateUserBranchDefault($branchId: Int!) {
    data: updateUserBranchDefault(branchId: $branchId) {
      data {
        id
      }
      message
    }
  }
`;

// export const createProduct = gql`
// mutation createProduct($data: ProductInput!) {
//   data: createProduct(data: $data) {
//     data {
//       id
//       ... on Products {
//         id
//         code
//       }
//     }
//     message
//   }
// }
// `

export const productImport = gql`
  mutation productImport(
    $branchId: Int!
    $data: WarehouseInReceiptInput!
    $incomeData: [IncomeExpenseInput]!
  ) {
    data: productImport(
      branchId: $branchId
      data: $data
      incomeData: $incomeData
    ) {
      data {
        id
      }
      msgCode
    }
  }
`;

export const exportProduct = gql`
  mutation exportProduct(
    $branchId: Int!
    $dataReq: WarehouseOutReceiptInput!
    $incomeData: [IncomeExpenseInput]!
  ) {
    data: exportProduct(
      branchId: $branchId
      dataReq: $dataReq
      incomeData: $incomeData
    ) {
      data {
        id
      }
      msgCode
    }
  }
`;

export const productInventoryStatus = gql`
  query productInventoryStatus($branchId: Int!, $prodId: Int!) {
    data: productInventoryStatus(branchId: $branchId, prodId: $prodId) {
      prdName
      prdCode
      prdImg
      stockId
      stockName
      quantity
      branchId
      branchName
      batchName
      expiryDate
      manufacturingDate
    }
  }
`;

export const completedTransferWarehouse = gql`
  mutation completedTransferWarehouse(
    $warehouseReceiptId: Int!
    $receiverBranchId: Int!
  ) {
    data: completedTransferWarehouse(
      warehouseReceiptId: $warehouseReceiptId
      receiverBranchId: $receiverBranchId
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const updateRoleDetails = gql`
  mutation updateRoleDetails($companyId: Int!, $data: [RoleDetailsInput]!) {
    data: updateRoleDetails(companyId: $companyId, data: $data) {
      data {
        id
      }
      message
    }
  }
`;

export const createRole = gql`
  mutation createRole($companyId: Int!, $name: String!, $description: String) {
    data: createRole(
      companyId: $companyId
      name: $name
      description: $description
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const createUserBranches = gql`
  mutation createUserBranches(
    $userId: Int!
    $branchId: Int!
    $companyId: Int!
  ) {
    data: createUserBranches(
      userId: $userId
      branchId: $branchId
      companyId: $companyId
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const updateUserBranchRole = gql`
  mutation updateUserBranchRole(
    $companyId: Int!
    $userBranchId: Int!
    $roleIds: [Int]!
  ) {
    data: updateUserBranchRole(
      companyId: $companyId
      userBranchId: $userBranchId
      roleIds: $roleIds
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const createCard = gql`
  mutation createCard($cardNumber: String!, $branchId: Int!) {
    data: createCard(cardNumber: $cardNumber, branchId: $branchId) {
      data {
        id
      }
      message
    }
  }
`;

export const updateCard = gql`
  mutation updateCard($cardNumber: String, $branchId: Int!, $cardId: Int!) {
    data: updateCard(
      cardNumber: $cardNumber
      branchId: $branchId
      cardId: $cardId
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const deleteCard = gql`
  mutation deleteCard($branchId: Int!, $cardId: Int!) {
    data: deleteCard(branchId: $branchId, cardId: $cardId) {
      data {
        id
      }
      message
    }
  }
`;

export const createEmployee = gql`
  mutation createEmployee($companyId: Int!, $data: EmployeeInput!) {
    data: createEmployee(companyId: $companyId, data: $data) {
      data {
        id
      }
      message
    }
  }
`;

export const updateEmployee = gql`
  mutation updateEmployee($companyId: Int!, $data: EmployeeInput!) {
    data: updateEmployee(companyId: $companyId, data: $data) {
      data {
        id
      }
      message
    }
  }
`;

export const cancelTransferWarehouse = gql`
  mutation cancelTransferWarehouse(
    $warehouseReceiptId: Int!
    $transferBranchId: Int!
  ) {
    data: cancelTransferWarehouse(
      warehouseReceiptId: $warehouseReceiptId
      transferBranchId: $transferBranchId
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const createLargeGroup = gql`
  mutation createLargeGroup(
    $companyId: Int!
    $largeGroupName: String!
    $largeGroupCode: String!
    $urlImage: String
  ) {
    data: createLargeGroup(
      companyId: $companyId
      largeGroupName: $largeGroupName
      largeGroupCode: $largeGroupCode
      urlImage: $urlImage
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const createSupplier = gql`
  mutation createSupplier($data: SupplierInput!) {
    data: createSupplier(data: $data) {
      data {
        id
      }
      message
    }
  }
`;
export const updateSupplier = gql`
  mutation updateSupplier($data: SupplierInput!) {
    data: updateSupplier(data: $data) {
      data {
        id
      }
      message
    }
  }
`;

export const createProductUnit = gql`
  mutation createProductUnit($companyId: Int!, $req: ProductUnitInput!) {
    data: createProductUnit(req: $req, companyId: $companyId) {
      data {
        id
      }
      message
    }
  }
`;

export const updateProductUnit = gql`
  mutation updateProductUnit($req: ProductUnitInput!, $companyId: Int!) {
    data: updateProductUnit(req: $req, companyId: $companyId) {
      data {
        id
      }
      message
    }
  }
`;

export const createMembershipRank = gql`
  mutation createMembershipRank($data: MembershipRankInput!, $companyId: Int!) {
    data: createMembershipRank(data: $data, companyId: $companyId) {
      data {
        id
      }
      message
    }
  }
`;

export const updateMembershipRank = gql`
  mutation updateMembershipRank($data: MembershipRankInput!, $companyId: Int!) {
    data: updateMembershipRank(data: $data, companyId: $companyId) {
      data {
        id
      }
      message
    }
  }
`;

export const deleteMembershipRank = gql`
  mutation deleteMembershipRank($rankId: Int!, $companyId: Int!) {
    data: deleteMembershipRank(rankId: $rankId, companyId: $companyId) {
      data {
        id
      }
      message
    }
  }
`;

export const createSmallGroup = gql`
  mutation createSmallGroup($data: SmallGroupInput!) {
    data: createSmallGroup(data: $data) {
      data {
        id
      }
      message
    }
  }
`;

export const addEmployeeBranch = gql`
  mutation addEmployeeBranch($employeeId: Int!, $branchId: Int!) {
    data: addEmployeeBranch(employeeId: $employeeId, branchId: $branchId) {
      data {
        id
      }
      message
    }
  }
`;

export const removeEmployeeBranch = gql`
  mutation removeEmployeeBranch($id: Int!, $branchId: Int!) {
    data: removeEmployeeBranch(id: $id, branchId: $branchId) {
      data {
        id
      }
      message
    }
  }
`;

export const removeUserRole = gql`
  mutation removeUserRole($roleId: Int!, $branchId: Int!, $userBranchId: Int!) {
    data: removeUserRole(
      roleId: $roleId
      branchId: $branchId
      userBranchId: $userBranchId
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const removeUserBranch = gql`
  mutation removeUserBranch($userId: Int!, $branchId: Int!) {
    data: removeUserBranch(userId: $userId, branchId: $branchId) {
      data {
        id
      }
      message
    }
  }
`;

export const createMiddleGroup = gql`
  mutation createMiddleGroup(
    $companyId: Int!
    $largeGroupId: Int!
    $middleGroupName: String!
    $middleGroupCode: String!
    $urlImage: String
  ) {
    data: createMiddleGroup(
      companyId: $companyId
      largeGroupId: $largeGroupId
      middleGroupName: $middleGroupName
      middleGroupCode: $middleGroupCode
      urlImage: $urlImage
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const createDiscount = gql`
  mutation createDiscount($companyId: Int!, $data: DiscountInput!) {
    data: createDiscount(companyId: $companyId, data: $data) {
      data {
        id
      }
      message
    }
  }
`;

export const updateDiscount = gql`
  mutation updateDiscount($companyId: Int!, $data: DiscountInput!) {
    data: updateDiscount(companyId: $companyId, data: $data) {
      data {
        id
      }
      message
    }
  }
`;

export const deleteDiscount = gql`
  mutation deleteDiscount($companyId: Int!, $discountId: Int!) {
    data: deleteDiscount(companyId: $companyId, discountId: $discountId) {
      data {
        id
      }
      message
    }
  }
`;

export const createCardType = gql`
  mutation createCardType(
    $typeName: String!
    $branchId: Int!
    $isDirect: Boolean!
  ) {
    data: createCardType(
      typeName: $typeName
      branchId: $branchId
      isDirect: $isDirect
    ) {
      data {
        id
      }
      message
    }
  }
`;

export const updateCardType = gql`
  mutation updateCardType(
    $typeName: String!
    $branchId: Int!
    $id: Int!
    $isDirect: Boolean!
  ) {
    data: updateCardType(
      typeName: $typeName
      branchId: $branchId
      id: $id
      isDirect: $isDirect
    ) {
      data {
        id
      }
      message
    }
  }
`;
export const updateTransferWarehouse = gql`
  mutation updateTransferWarehouse(
    $data: WarehouseReceiptInput!
    $incomeData: [IncomeExpenseInput]
  ) {
    data: updateTransferWarehouse(data: $data, incomeExpenseReqs: $incomeData) {
      data {
        id
      }
      message
    }
  }
`;

export const transferWarehouseApi = gql`
  mutation transferWarehouse(
    $data: WarehouseReceiptInput!
    $incomeData: [IncomeExpenseInput]
  ) {
    data: transferWarehouse(data: $data, incomeExpenseReqs: $incomeData) {
      data {
        id
      }
      message
    }
  }
`;

export const warehouseOutReceipts = gql`
  query warehouseOutReceipts(
    $branchIds: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: warehouseOutReceipts(
      branchIds: $branchIds
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on WarehouseOutReceipt {
          id
          code
          totalMoney
          createById
          totalQuantity
          warehouseOutType
          dateTime
          description
          totalCost
          totalBeforeCost
          receiverCode
          receiverName
          users {
            id
            userName
          }
          branchId
          branchName
        }
      }
      totalCount
    }
  }
`;

export const warehouseOutDetailReceipts = gql`
  query warehouseOutReceipts(
    $branchIds: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: warehouseOutReceipts(
      branchIds: $branchIds
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on WarehouseOutReceipt {
          id
          code
          totalMoney
          createById
          totalQuantity
          warehouseOutType
          totalCost
          totalBeforeCost
          receiverCode
          receiverName
          branchId
          branchName
          dateTime
          description
          items: warehouseOutReceiptDetails {
            id
            warehouse {
              id
              code
              prdId
              prdName
              prdCode
              prdImg
              stockId
              stockName
              unitId
              unitName
              salePrice
              costPrice
              quantity
              batchName
              expiryDate
              manufacturingDate
            }
          }
        }
      }
      totalCount
    }
  }
`;
export const transferWarehouseList = gql`
  query transferWarehouseList(
    $branchIds: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: transferWarehouseList(
      branchIds: $branchIds
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on WarehouseReceipts {
          id
          code
          moneyTotal
          quantityTotal
          transferUserName
          receiverUserName
          transferBranchName
          transferBranchId
          receiverBranchName
          receiverBranchId
          status
          transferDate
          description

          totalBeforeCost
          totalCostOut
          totalCostIn
        }
      }
      totalCount
    }
  }
`;

export const transferWarehouseDetail = gql`
  query transferWarehouseList(
    $branchIds: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: transferWarehouseList(
      branchIds: $branchIds
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on WarehouseReceipts {
          id
          code
          moneyTotal
          quantityTotal
          transferUserName
          receiverUserName
          transferBranchName
          transferBranchId
          receiverBranchName
          receiverBranchId

          totalBeforeCost
          totalCostOut
          totalCostIn

          status
          transferDate
          description

          costOut: warehouseReceiptOutExternalCosts {
            id
            name
            code
            amount
            paymentType
            cardType
            cardNumber
            receiverName
            receiverCode
          }

          costIn: warehouseReceiptInExternalCosts {
            id
            code
            name
            amount
            paymentType
            cardType
            cardNumber
            receiverName
            receiverCode
          }

          itemsOut: warehouseReceiptDetails {
            id
            prdId
            prdName
            prdCode
            prdImg
            prdProperties
            prdDetails
            quantity
            salePrice
            total
            transferStockId
            transferStockName
            receiverStockId
            receiverStockName
            description
            unitId
            unitName

            batchName
            expiryDate
            manufacturingDate
          }

          itemsIn: warehouseReceiptInDetails {
            id
            prdId
            prdName
            prdCode
            prdImg
            prdProperties
            prdDetails
            quantity
            salePrice
            total
            transferStockId
            transferStockName
            receiverStockId
            receiverStockName
            description
            unitId
            unitName

            batchName
            expiryDate
            manufacturingDate
          }
        }
      }
      totalCount
    }
  }
`;

export const findInventoryByStockId = gql`
  query findInventoryByStockId(
    $stockId: Int!
    $branchId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findInventoryByStockId(
      stockId: $stockId
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on Inventory {
          id
          prdId
          prdCode
          prdName
          availableQty: quantity
          inventoryDate
          productUnit
          price
          costPrice
        }
      }
      totalCount
    }
  }
`;

export const warehouseInReceipt = gql`
  query inventoryReceivingList(
    $branchIds: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: inventoryReceivingList(
      branchIds: $branchIds
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on WarehouseInReceipt {
          id
          code
          description
          totalQuantity
          totalMoney
          dateTime
          totalCost
          suppliers {
            id
            supplierName
            supplierCode
          }
          totalBeforeCost
          costs: warehouseInExternalCosts {
            id
            name
            amount
          }
          users {
            id
            userName
          }
          branchId
          branchName
          pos: purchaseOrdersRefs {
            po: purchaseOrders {
              id
              code
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const warehouseInReceiptDetail = gql`
  query inventoryReceivingList(
    $branchIds: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: inventoryReceivingList(
      branchIds: $branchIds
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on WarehouseInReceipt {
          id
          code
          totalQuantity
          totalMoney
          dateTime
          description
          branchName
          totalCost
          totalBeforeCost
          suppliers {
            id
            supplierName
            supplierCode
          }
          users {
            id
            userName
          }
          pos: purchaseOrdersRefs {
            po: purchaseOrders {
              id
              code
            }
          }
          items: warehouseInReceiptDetails {
            id
            cost
            priceBeforeCost
            warehouse {
              id
              code
              prdId
              prdName
              prdCode
              supplierId
              supplierName
              quantity
              salePrice
              costPrice
              stockId
              stockName
              unitName
              unitId
              expiryDate
              batchName
              manufacturingDate
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const salesReport = gql`
  query salesReport($fromDate: Date!, $toDate: Date!, $branchIds: [Int]!) {
    data: salesReport(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
    ) {
      total
      costTotal
      discountTotal
      grandTotalBeforeTax
      taxMoneyTotal
      grandTotal
      depositTotal
      depositCollectedTotal
      paymentTotal
      totalPaymentDetails {
        currency
        value
        total
      }
      changeMoney
      cashPaymentTotal
      cashPaymentDetails {
        currency
        value
        total
      }
      cardPaymentTotal
      cardPaymentDetails {
        cardNumber
        cardType
        total
      }
      reduceSalesTotal
      reduceCommission
      reduceMoneyGate
      realRevenueTotal
      invoiceCounts
      avgInvoice
      guestsTotal
      avgExpense
      firstDebt
      incurredDebt
      paidDebt
      remainDebt
      returnInvoiceCounts
      salesCounts
      discountMoney
      discountVoucher
      discountMember
      discountPointMember
      discountProduct
      discountCustomerProduct
      surchargeProductTotal
      surcharges {
        surchargeType
        surchargeValue
      }
    }
  }
`;
export const changeUserPassword = gql`
  mutation changePass(
    $companyId: Int!
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      companyId: $companyId
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      statusCode
      msgCode
      message
    }
  }
`;

export const resetUserPassword = gql`
  mutation resetPassword($companyId: Int!, $userName: String!) {
    resetPassword(companyId: $companyId, userName: $userName) {
      statusCode
      msgCode
      message
    }
  }
`;

export const updateEmployeeToUser = gql`
  mutation updateEmployeeToUser(
    $companyId: Int!
    $employeeId: Int!
    $userId: Int!
  ) {
    updateEmployeeToUser(
      companyId: $companyId
      employeeId: $employeeId
      userId: $userId
    ) {
      statusCode
      msgCode
      message
    }
  }
`;

// export const importExcelProducts = gql`
//   mutation importExcelProducts($companyId: Int!, $url: String!) {
//     data: importExcelProducts(companyId: $companyId, url: $url) {
//       statusCode
//       msgCode
//       message
//       data
//     }
//   }
// `;

export const warehouseImportExcel = gql`
  mutation warehouseImportExcel(
    $branchId: Int!
    $url: String!
    $description: String
  ) {
    data: warehouseImportExcel(
      branchId: $branchId
      url: $url
      description: $description
    ) {
      statusCode
      msgCode
      message
      data
    }
  }
`;
export const productTransferImport = gql`
  mutation productTransferImport(
    $url: String!
    $transferBranchId: Int!
    $receiverBranchId: Int!
    $description: String
  ) {
    data: productTransferImport(
      url: $url
      transferBranchId: $transferBranchId
      receiverBranchId: $receiverBranchId
      description: $description
    ) {
      statusCode
      msgCode
      message
      data
    }
  }
`;

export const exportProductsExcel = gql`
  mutation exportProductsExcel(
    $branchId: Int!
    $url: String!
    $description: String
    $outType: WarehouseOutType!
  ) {
    data: exportProductsExcel(
      branchId: $branchId
      url: $url
      description: $description
      outType: $outType
    ) {
      statusCode
      msgCode
      message
      data
    }
  }
`;

export const orderReturns = gql`
  mutation orderReturns(
    $data: InvoiceInput!
    $orderRefId: Int
    $incomeData: [IncomeExpenseInput]!
  ) {
    data: orderReturns(
      data: $data
      orderRefId: $orderRefId
      incomeData: $incomeData
    ) {
      statusCode
      msgCode
      message
      data {
        id
        ... on Invoices {
          code
        }
      }
    }
  }
`;

export const createAppConfig = gql`
  mutation createAppConfig(
    $keyName: String!
    $keyValues: String!
    $id: Int!
    $isBranch: Boolean
  ) {
    data: createAppConfig(
      keyName: $keyName
      keyValues: $keyValues
      id: $id
      isBranch: $isBranch
    ) {
      statusCode
      msgCode
      message
      data {
        id
      }
    }
  }
`;

export const createMultiCustomers = gql`
  mutation createMultiCustomers($data: [CustomerRequest]!, $companyId: Int!) {
    data: createMultiCustomers(data: $data, companyId: $companyId) {
      statusCode
      msgCode
      message
    }
  }
`;

const shiftSales = `
    shiftName
    fromDate
    endDate
   
    realRevenueTotalByDate
    salesReport{
      total
      costTotal
      discountTotal
      grandTotalBeforeTax
      taxMoneyTotal
      grandTotal
      depositTotal
      depositCollectedTotal
      paymentTotal
      totalPaymentDetails{
        currency
        value
        total
      }
      changeMoney
      cashPaymentTotal
      cashPaymentDetails{
        currency
        value
        total
      }
      cardPaymentTotal
      cardPaymentDetails{
        cardNumber
        cardType
        total
      }
      reduceSalesTotal
      reduceCommission
      reduceMoneyGate
      realRevenueTotal
      invoiceCounts
      avgInvoice
      guestsTotal
      avgExpense
      
      paidDebt
      firstDebt
      remainDebt
      incurredDebt

    }
`;

export const outClockShiftSales = gql`
mutation outClockShiftSales($branchId: Int!, $userReceiverId: Int!) {
  data: outClockShiftSales(branchId: $branchId, userReceiverId: $userReceiverId) {
    ${shiftSales}
    usersTransfer{
      id
      userName
    }
    usersReceiver{
      id
      userName
    }
  }
}`;
export const productStockCard = gql`
  query productStockCard(
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
    $productId: [Int]!
    $stockId: [Int]!
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: productStockCard(
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
      productId: $productId
      stockId: $stockId
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const getShiftSales = gql`
query getShiftSales($branchId: Int!) {
  data: getShiftSales(branchId: $branchId) {
    ${shiftSales}
  }
}
`;

export const warehouseInBySupplierDetail = gql`
  query warehouseInBySupplierDetail(
    $branchIds: [Int]!
    $fromDate: Date!
    $supplierId: Int!
    $toDate: Date!
  ) {
    data: warehouseInBySupplierDetail(
      branchIds: $branchIds
      fromDate: $fromDate
      supplierId: $supplierId
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const purchaseOrderSummaryBySupplier = gql`
  query purchaseOrderSummaryBySupplier(
    $branchIds: [Int]!
    $fromDate: Date!
    $supplierId: Int!
    $toDate: Date!
  ) {
    data: purchaseOrderSummaryBySupplier(
      branchIds: $branchIds
      fromDate: $fromDate
      supplierId: $supplierId
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const productChannelReport = gql`
  query productChannelReport(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: productChannelReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const incomeExpensePaymentReport = gql`
  query incomeExpensePaymentReport(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: incomeExpensePaymentReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const supplierSalesReport = gql`
  query supplierSalesReport(
    $where: Json
    $orderBy: String
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: supplierSalesReport(
      where: $where
      orderBy: $orderBy
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const summaryReport = gql`
  query summaryReport($companyId: Int!) {
    data: summaryReport(companyId: $companyId) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const revenueDetailReport = gql`
  query revenueDetailReport(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: revenueDetailReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const findShiftSalesReport = gql`
query findShiftSalesReport($branchId: Int!, $toDate: Date!, $fromDate: Date!, $where: Json, $orderBy: String, $limit: Int, $skip: Int) {
  data: findShiftSalesReport(branchId: $branchId, toDate: $toDate,  fromDate: $fromDate, where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
    list {
      id
      ... on ShiftSalesReport {
        id
        ${shiftSales}
        usersTransfer{
          id
          userName
        }
        usersReceiver{
          id
          userName
        }
      }
    }
    totalCount
  }
}
`;

export const updateMultiProducts = gql`
  mutation updateMultiProducts($data: [ProductInput]!) {
    data: updateMultiProducts(data: $data) {
      statusCode
      msgCode
      messageEn
      data {
        id
      }
    }
  }
`;

export const paymentInvoiceDebt = gql`
  mutation paymentInvoiceDebt(
    $data: [InvoiceDebInput]!
    $incomeData: [IncomeExpenseInput]!
    $branchId: Int!
  ) {
    data: paymentInvoiceDebt(
      data: $data
      incomeData: $incomeData
      branchId: $branchId
    ) {
      statusCode
      msgCode
      message
      messageEn
      data {
        id
      }
    }
  }
`;

export const createProductConfig = gql`
  mutation createProductConfig($data: ProductConfigInput!) {
    data: createProductConfig(data: $data) {
      statusCode
      msgCode
      message
      messageEn
      data {
        id
      }
    }
  }
`;

export const findMinQuantityWarning = gql`
  query findMinQuantityWarning(
    $branchIds: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findMinQuantityWarning(
      branchIds: $branchIds
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on Inventory {
          id
          prdId
          prdCode
          prdName
          prdImg
          prdProperties
          prdDetails
          supplierId
          supplierName
          quantity
          stockId
          stockName
          branchName
          companyId
          companyName
          refId
          productUnit
          type
          inventoryDate
          description
        }
      }
      totalCount
    }
  }
`;
export const groupSalesReport = gql`
  query groupSalesReport(
    $where: Json
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: groupSalesReport(
      where: $where
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;
export const productSalesTopReport = gql`
  query productSalesTopReport(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
    $limit: Int
  ) {
    data: productSalesTopReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      limit: $limit
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const findOutOfStockByBranch = gql`
  query findOutOfStockByBranch(
    $branchIds: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findOutOfStockByBranch(
      branchIds: $branchIds
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on Inventory {
          id
          prdId
          prdCode
          prdName
          prdImg
          prdProperties
          prdDetails
          supplierId
          supplierName
          quantity
          stockId
          stockName
          branchName
          companyId
          companyName
          productUnit
          refId
          type
          inventoryDate
          description
        }
      }
      totalCount
    }
  }
`;

export const profitProductTopReport = gql`
  query profitProductTopReport(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
    $limit: Int
  ) {
    data: profitProductTopReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      limit: $limit
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const findAllCircularCategory = gql`
  query findAllCircularCategory(
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findPage(
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on CircularCategory {
          id
          code
          name
          accountantType
        }
      }
    }
  }
`;
export const getCircularCategory = gql`
  query getCircularCategory($where: Json, $orderBy: String) {
    data: getCircularCategory(where: $where, orderBy: $orderBy) {
      id
      code
      name
      accountantType
    }
  }
`;

export const getCompanyCircularCategory = gql`
  query getCompanyCircularCategory($where: Json, $orderBy: String) {
    data: getCompanyCircularCategory(where: $where, orderBy: $orderBy) {
      id
      code
      name
      parent {
        id
        code
        name
      }
    }
  }
`;

export const getBranchCircularCategory = gql`
  query getBranchCircularCategory($where: Json, $orderBy: String) {
    data: getBranchCircularCategory(where: $where, orderBy: $orderBy) {
      id
      companyCircularCategory {
        id
        code
        name
      }
    }
  }
`;

export const createCompanyCircularCategory = gql`
  mutation createCompanyCircularCategory(
    $data: [CompanyCircularCategoryInput]!
    $companyId: Int!
  ) {
    data: createCompanyCircularCategory(data: $data, companyId: $companyId) {
      statusCode
      msgCode
      messageEn
      data {
        id
      }
    }
  }
`;

export const updateCompanyCircularCategory = gql`
  mutation updateCompanyCircularCategory(
    $id: Int!
    $data: [CompanyCircularCategoryInput]!
    $companyId: Int!
  ) {
    data: updateCompanyCircularCategory(
      id: $id
      data: $data
      companyId: $companyId
    ) {
      statusCode
      msgCode
      messageEn
      data {
        id
      }
    }
  }
`;

export const deleteCompanyCircularCategory = gql`
  mutation deleteCompanyCircularCategory($id: Int!, $companyId: Int!) {
    data: deleteCompanyCircularCategory(id: $id, companyId: $companyId) {
      statusCode
      msgCode
      messageEn
      data {
        id
      }
    }
  }
`;

export const createBranchCircularCategory = gql`
  mutation createBranchCircularCategory($data: [BranchCircularCategoryReq]!) {
    data: createBranchCircularCategory(data: $data) {
      statusCode
      msgCode
      messageEn
      data {
        id
      }
    }
  }
`;

export const updateBranchCircularCategory = gql`
  mutation updateBranchCircularCategory(
    $data: BranchCircularCategoryReq!
    $id: Int!
  ) {
    data: updateBranchCircularCategory(data: $data, id: $id) {
      statusCode
      msgCode
      messageEn
      data {
        id
      }
    }
  }
`;

export const deleteBranchCircularCategory = gql`
  mutation deleteBranchCircularCategory($id: Int!, $branchId: Int!) {
    data: deleteBranchCircularCategory(id: $id, branchId: $branchId) {
      statusCode
      msgCode
      messageEn
      data {
        id
      }
    }
  }
`;

export const createIncomeExpense = gql`
  mutation createIncomeExpense($data: [IncomeExpenseInput]!, $branchId: Int!) {
    data: createIncomeExpense(data: $data, branchId: $branchId) {
      statusCode
      msgCode
      messageEn
      data {
        id
      }
    }
  }
`;

export const updateIncomeExpense = gql`
  mutation updateIncomeExpense(
    $data: IncomeExpenseInput
    $branchId: Int!
    $id: Int!
  ) {
    data: updateIncomeExpense(data: $data, branchId: $branchId, id: $id) {
      statusCode
      msgCode
      messageEn
      data {
        id
      }
    }
  }
`;

export const incomeExpenseSummary = gql`
  query incomeExpenseSummary(
    $fromDate: Date!
    $toDate: Date!
    $branches: [Int]!
  ) {
    data: incomeExpenseSummary(
      fromDate: $fromDate
      toDate: $toDate
      branches: $branches
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const createConfigAutoFinance = gql`
  mutation createConfigAutoFinance(
    $data: [ConfigAutoFinanceInput]!
    $branchId: Int!
  ) {
    data: createConfigAutoFinance(data: $data, branchId: $branchId) {
      statusCode
      msgCode
      message
    }
  }
`;

export const deleteConfigAutoFinance = gql`
  mutation deleteConfigAutoFinance($branchId: Int!, $id: Int!) {
    data: deleteConfigAutoFinance(id: $id, branchId: $branchId) {
      statusCode
      msgCode
      message
    }
  }
`;

export const paymentPurchaseOrder = gql`
  mutation paymentPurchaseOrder(
    $data: [PurchaseOrderUpdateInput]!
    $branchId: Int!
    $incomeExpenseReqs: [IncomeExpenseInput]
  ) {
    data: paymentPurchaseOrder(
      data: $data
      branchId: $branchId
      incomeExpenseReqs: $incomeExpenseReqs
    ) {
      statusCode
      msgCode
      message
    }
  }
`;

export const updateConfigAutoFinance = gql`
  mutation updateConfigAutoFinance(
    $id: Int!
    $data: ConfigAutoFinanceInput!
    $branchId: Int!
  ) {
    data: updateConfigAutoFinance(id: $id, data: $data, branchId: $branchId) {
      statusCode
      msgCode
      message
    }
  }
`;

export const createPurchaseOrder = gql`
  mutation createPurchaseOrder(
    $data: [PurchaseOrderInput]!
    $branchId: Int!
    $incomeExpenseReqs: [IncomeExpenseInput]
  ) {
    data: createPurchaseOrder(
      data: $data
      branchId: $branchId
      incomeExpenseReqs: $incomeExpenseReqs
    ) {
      statusCode
      msgCode
      message
      data {
        id
      }
    }
  }
`;

export const updatePurchaseOrder = gql`
  mutation updatePurchaseOrder(
    $poId: Int!
    $data: PurchaseOrderInput!
    $branchId: Int!
  ) {
    data: updatePurchaseOrder(poId: $poId, data: $data, branchId: $branchId) {
      statusCode
      msgCode
      message
    }
  }
`;

export const getConfigAutoFinance = gql`
  query getConfigAutoFinance($where: Json, $orderBy: String) {
    data: getConfigAutoFinance(where: $where, orderBy: $orderBy) {
      id
      elementCode
      debt {
        id
        companyCircularCategory {
          id
          name
          code
          accountantType
        }
      }
      credit {
        id
        companyCircularCategory {
          id
          name
          code
          accountantType
        }
      }
      keyPayment
    }
  }
`;

export const findIncomeExpense = gql`
  query findIncomeExpense(
    $branchId: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findIncomeExpense(
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on IncomeExpense {
          id
          code
          totalPayment
          transfer
          description
          transferCode
          datePayment
          transferRepresent
          receiverRepresent
          receiver
          receiverCode
          startDate
          endDate
          accountantType
          description
          address
          codeNumber
          bookNo
          elementCode
          elementName
          branches {
            id
            branchName
            branchCode
            address
          }
          incomeExpenseRefs {
            id
            invoices {
              id
              code
            }
            purchaseOrders {
              id
              code
            }
            warehouseOutReceipt {
              id
              code
            }
            warehouseReceipts {
              id
              code
            }
            customers {
              id
              customerCode
              customerName
            }
            suppliers {
              id
              supplierName
            }
            employees {
              id
              fullName
            }
          }
          isManual
          paymentDetails: incomeExpensePaymentDetails {
            id
            currencyUnit
            originalCurrency
            exchangePrice
            totalPayment
            isCashPayment
            cardType
            cardNumber
          }
          payments: incomeExpensePayments {
            id
            totalPayment
            paymentTime
            isManual
            receiver
            receiverCode
            transfer
            transferCode
            debtAcc {
              id
              detail: companyCircularCategory {
                id
                code
                name
              }
            }
            availableAcc {
              id
              detail: companyCircularCategory {
                id
                code
                name
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

const poObject = `
id
code
payment
grandTotal
paymentStatus
status
totalQuantity
quantity
inventoryStatus
taxMoney
totalBeforeTax
branches{
  id
  branchCode
  branchName
}
purchaseCreateDate
fromDate
endDate
suppliers{
  id
  supplierCode
  supplierName
  address
}
description
`;

export const findPaginationSupplierByCompany = gql`
  query findPaginationSupplierByCompany(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findPaginationSupplierByCompany(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on Suppliers {
          id
          supplierName
          supplierCode
          address
          phoneNumber
          taxNumber
          isInternal
          process
          email
          note
          totalDebt
          debtLimit
          maxDaysDebt
          totalCredit
          overdueDebt
          supplierBranches {
            id
            branches {
              id
              branchCode
              branchName
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const findPurchaseOrders = gql`
query findPurchaseOrders($branches:[Int]!, $where: Json, $orderBy: String, $limit: Int,  $skip: Int) {
  data: findPurchaseOrders(branches: $branches, where: $where,orderBy : $orderBy, limit: $limit, skip: $skip) {
    list {
      id
      ... on PurchaseOrders {
          ${poObject}
      }
    }
    totalCount
  }
}
`;

export const findCustomerOrderHistory = gql`
  query findCustomerOrderHistory(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findCustomerOrderHistory(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on CustomerPointHistory {
          invoices {
            id
            code
          }
          point
          historyTime
          description
        }
      }
      totalCount
    }
  }
`;

export const findPurchaseOrderDetail = gql`
query findPurchaseOrders($branches:[Int]!, $where: Json, $orderBy: String, $limit: Int,  $skip: Int) {
  data: findPurchaseOrders(branches: $branches, where: $where,orderBy : $orderBy, limit: $limit, skip: $skip) {
    list {
      id
      ... on PurchaseOrders {
          ${poObject}
          items:purchaseOrdersDetails{
            id
            product{
              id
              code
              name
              productType
              unit{
                id
                name
              }
              saleOver
              isBundle
              noCheckWarehouse
            }
            quantity
            price
            total
            unitTransfer
            transfer
            percentTax
            taxMoney
            totalBeforeTax
            suppliers{
              id
              supplierCode
              supplierName
            }
          }
          pos:purchaseOrdersRefs{
            id
            serviceReceipt {
              id
              code
              totalQuantity
              totalMoney
              description
              dateTime
              items: serviceReceiptDetails{
                id
                products{
                  id
                  name
                }
                quantity
                price
                total
              }
            }
            data:warehouseInReceipt{
              id
              code
              totalQuantity
              totalMoney
              description
              dateTime
              totalCost
              totalBeforeCost
              users{
                id
                userName
              }
              items:warehouseInReceiptDetails{
                id
                warehouse{
                  id
                  prdId
                  quantity
                  costPrice
                }
              }
            }
          }
          purchaseOrderCardPayments{
            id
            cardType
            cardNumber
            totalPayment
          }
          purchaseOrderCashPayments{
            id
            currencyUnit
            totalCash
            exchangePrice
            totalPayment
          }
          income:incomeExpenseRefs{
            id
            data:incomeExpense{
              id
              code
              totalPayment
              description
              startDate
              receiver
              receiverCode
              transfer
              transferCode
              transferRepresent
              receiverRepresent
              address
            }
          }
      }
    }
    totalCount
  }
}
`;

export const revenueSaleByDate = gql`
  query revenueSaleByDate($fromDate: Date!, $toDate: Date!, $branches: [Int]!) {
    data: revenueSaleByDate(
      fromDate: $fromDate
      toDate: $toDate
      branches: $branches
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const revenueCostSaleByDate = gql`
  query revenueCostSaleByDate(
    $fromDate: Date!
    $toDate: Date!
    $branches: [Int]!
  ) {
    data: revenueCostSaleByDate(
      fromDate: $fromDate
      toDate: $toDate
      branches: $branches
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const warehouseInReport = gql`
  query warehouseInReport($fromDate: Date!, $toDate: Date!, $branches: [Int]!) {
    data: warehouseInReport(
      fromDate: $fromDate
      toDate: $toDate
      branches: $branches
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;
export const warehouseOutReport = gql`
  query warehouseOutReport(
    $fromDate: Date!
    $toDate: Date!
    $branches: [Int]!
  ) {
    data: warehouseOutReport(
      fromDate: $fromDate
      toDate: $toDate
      branches: $branches
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const inventoryReport = gql`
  query inventoryReport($fromDate: Date!, $toDate: Date!, $branches: [Int]!) {
    data: inventoryReport(
      fromDate: $fromDate
      toDate: $toDate
      branches: $branches
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;
export const findGroupInventory = gql`
  query findGroupInventory(
    $branchIds: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findGroupInventory(
      branchIds: $branchIds
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;
export const revenueSaleByBranch = gql`
  query revenueSaleByBranch(
    $fromDate: Date!
    $toDate: Date!
    $branchIds: [Int]!
  ) {
    data: revenueSaleByBranch(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }

    dataCount: countRevenueSaleByBranch(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const summaryReportByRange = gql`
  query summaryReportByRange(
    $fromDate: Date!
    $toDate: Date!
    $branchIds: [Int]!
  ) {
    data: summaryReportByRange(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const updatePointCustomer = gql`
  mutation updatePointCustomer($companyId: Int!, $data: [CustomerPointInput]!) {
    data: updatePointCustomer(companyId: $companyId, data: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const deleteIncomeExpense = gql`
  mutation deleteIncomeExpense($id: Int!, $branchId: Int!) {
    data: deleteIncomeExpense(id: $id, branchId: $branchId) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const updateSmallGroup = gql`
  mutation updateSmallGroup($data: SmallGroupInput!, $id: Int!) {
    data: updateSmallGroup(data: $data, id: $id) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const updateMiddleGroup = gql`
  mutation updateMiddleGroup(
    $companyId: Int!
    $id: Int!
    $code: String
    $name: String
    $urlImage: String
  ) {
    data: updateMiddleGroup(
      companyId: $companyId
      id: $id
      code: $code
      name: $name
      urlImage: $urlImage
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const updateLargeGroup = gql`
  mutation updateLargeGroup(
    $companyId: Int!
    $id: Int!
    $code: String
    $name: String
    $urlImage: String
  ) {
    data: updateLargeGroup(
      companyId: $companyId
      id: $id
      code: $code
      name: $name
      urlImage: $urlImage
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const transferProductReport = gql`
  query transferProductReport(
    $fromDate: Date!
    $toDate: Date!
    $branchIds: [Int]!
  ) {
    data: transferProductReport(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const saleChannelReport = gql`
  query saleChannelReport(
    $fromDate: Date!
    $toDate: Date!
    $branchIds: [Int]!
  ) {
    data: saleChannelReport(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }

    dataCount: countSaleChannelReport(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const purchaseOrderDetailReport = gql`
  query purchaseOrderDetailReport(
    $fromDate: Date!
    $toDate: Date!
    $branchIds: [Int]!
  ) {
    data: purchaseOrderDetailReport(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const incomeExpenseManualReport = gql`
  query incomeExpenseManualReport(
    $fromDate: Date!
    $toDate: Date!
    $branchIds: [Int]!
  ) {
    data: incomeExpenseManualReport(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const purchaseOrderSupplierReport = gql`
  query purchaseOrderSupplierReport(
    $fromDate: Date!
    $toDate: Date!
    $branchIds: [Int]!
  ) {
    data: purchaseOrderSupplierReport(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const getTopCustomer = gql`
  query getTopCustomer(
    $fromDate: Date!
    $toDate: Date!
    $branchIds: [Int]!
    $limit: Int
  ) {
    data: getTopCustomer(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
      limit: $limit
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const saleChannelReportTop = gql`
  query saleChannelReportTop(
    $fromDate: Date!
    $toDate: Date!
    $branchIds: [Int]!
    $limit: Int
  ) {
    data: saleChannelReportTop(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
      limit: $limit
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const getTopCustomerGroupBuyProduct = gql`
  query getTopCustomerGroupBuyProduct(
    $fromDate: Date!
    $toDate: Date!
    $branchIds: [Int]!
    $limit: Int
  ) {
    data: getTopCustomerGroupBuyProduct(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
      limit: $limit
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const getTopCustomerBuyProduct = gql`
  query getTopCustomerBuyProduct(
    $fromDate: Date!
    $toDate: Date!
    $branchIds: [Int]!
    $limit: Int
  ) {
    data: getTopCustomerBuyProduct(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
      limit: $limit
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }

    dataCount: countCustomerBuyInvoice(
      fromDate: $fromDate
      toDate: $toDate
      branchIds: $branchIds
    ) {
      statusCode
      msgCode
      message
      data
      totalCount
    }
  }
`;

export const findChannels = gql`
  query findChannels($companyId: Int!) {
    data: findChannels(companyId: $companyId) {
      id
      name
      isDefault
      isOnline
      priority
      description
    }
  }
`;

export const createSaleChannel = gql`
  mutation createSaleChannel($data: SaleChannelInput!, $companyId: Int!) {
    data: createSaleChannel(data: $data, companyId: $companyId) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const createSearchHistory = gql`
  mutation createSearchHistory($data: [SearchHistoryInput]!, $companyId: Int!) {
    data: createSearchHistory(data: $data, companyId: $companyId) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const updateSaleChannel = gql`
  mutation updateSaleChannel(
    $data: SaleChannelInput!
    $companyId: Int!
    $id: Int!
  ) {
    data: updateSaleChannel(data: $data, companyId: $companyId, id: $id) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

// export const addInvoiceProcessStep = gql`
//   mutation addInvoiceProcessStep(
//     $data: [InvoiceB2BUpdateInput]!
//     $branchId: Int!
//   ) {
//     data: addInvoiceProcessStep(data: $data, branchId: $branchId) {
//       statusCode
//       msgCode
//       messageEn
//     }
//   }
// `;

// export const completeOrderB2B = gql`
//   mutation completeOrderB2B(
//     $data: InvoiceInput!
//     $incomeData: [IncomeExpenseInput]!
//     $id: Int!
//   ) {
//     data: completeOrderB2B(data: $data, id: $id, incomeData: $incomeData) {
//       statusCode
//       msgCode
//       messageEn
//       __typename
//     }
//   }
// `;

export const getOrderTemplate = gql`
  query getOrderTemplate(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getOrderTemplate(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on OrderTemplate {
          templateName
        }
      }
    }
  }
`;

export const getCustomerSaleByBranch = gql`
  query getCustomerSaleByBranch(
    $customerId: Int!
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: getCustomerSaleByBranch(
      customerId: $customerId
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const getWorkOrders = gql`
  query getWorkOrders(
    $branchId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getWorkOrders(
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on WorkOrders {
          id
          code
          name
          invoices {
            id
            code
          }
          reporter {
            id
            fullName
          }
          status
          fromTime
          toTime
          targetTime
          description
        }
      }
    }
  }
`;

export const getWorkOrderDetail = gql`
  query getWorkOrders(
    $branchId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getWorkOrders(
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on WorkOrders {
          id
          code
          name
          reporter {
            id
            fullName
          }
          externalCosts {
            id
            name
            amount
          }
          status
          fromTime
          toTime
          targetTime
          description
          invoices {
            id
            code
            customers {
              id
              customerName
            }
          }
          proccess: workOrdersProcesses {
            id
            name
            processCode
            status
            reporter {
              id
              fullName
            }
            assignee {
              id
              fullName
            }
            fromTime
            toTime
            description
            subStatus
            totalMaterialBom
            totalMaterialBomDepreciation
            totalMaterialReal
            totalFinishedBom
            totalFinishedReal
            surcharge
            totalWaste
            totalLeft
            totalFinished
            productsFinished: processFinishedProducts {
              id
              productId
              productCode
              productName
              unitId
              unitName
              quantity
              cost
              totalCost
              finishedType
            }

            products: workOrderProcessDetails {
              id
              productName
              productId
              productCode
              productImg
              unitId
              unitName
              smallGroupId
              smallGroup
              middleGroup
              middleGroupId
              largeGroupId
              largeGroup
              productType
              productQuantity
              productPrice
              costPrice
              total
              costTotal

              exchangeUnitsId
              exchange
              exchangeUnitName

              totalMaterialBom
              totalMaterialBomDepreciation
              totalMaterialReal

              details: workOrderProcessPrdDetails {
                id
                productName
                productId
                productCode
                productImg
                unitId
                unitName
                smallGroupId
                productOrgQuantity
                smallGroup
                middleGroup
                middleGroupId
                largeGroupId
                largeGroup
                productType
                productQuantity
                productPrice
                costPrice
                total
                percentDepreciation
                costTotal
                realQuantity

                exchangeUnitsId
                exchange
                exchangeUnitName
              }
            }
          }
          products: workOrderDetails {
            id
            productName
            productId
            productCode
            productImg
            unitId
            unitName
            smallGroupId
            smallGroup
            middleGroup
            middleGroupId
            largeGroupId
            largeGroup
            productType
            productQuantity
            productPrice
            costPrice
            total
            costTotal

            exchangeUnitsId
            exchange
            exchangeUnitName

            details: workOrderProductDetails {
              id
              productName
              productId
              productCode
              productImg
              unitId
              unitName
              productOrgQuantity
              smallGroupId
              smallGroup
              middleGroup
              middleGroupId
              largeGroupId
              largeGroup
              productType
              productQuantity
              productPrice
              costPrice
              total
              percentDepreciation
              costTotal

              exchangeUnitsId
              exchange
              exchangeUnitName
            }
          }
        }
      }
    }
  }
`;

export const findWarehouseByCode = gql`
  query findWarehouseByCode(
    $branchId: Int!
    $codes: [String]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findWarehouseByCode(
      branchId: $branchId
      codes: $codes
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        ... on Warehouse {
          id
          code
          prdId
          prdName
          prdCode
          prdImg
          stockId
          stockName
          unitId
          unitName
          salePrice
          costPrice
          quantity
          batchName
          expiryDate
          manufacturingDate
          type
          description
        }
      }
    }
  }
`;

export const getCart = gql`
  query getCart(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getCart(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on Cart {
          id
          code
          customers {
            id
            rankId
            rankName
            address
            customerCode
            customerName
            phoneNumber
            point
            totalPoint
            genderType
            birthday
            legalName
            taxCode
            email
            note
            debtLimit
            totalCredit
            maxDaysDebt
            userCustomers {
              id
              userName
              lastLogin
            }
            customerGroup {
              id
              priceLevel {
                id
                name
                isDefault
              }
              groupName
              discountPercent
            }
            customerCompany {
              id
              shortName
              code
              urlLogo
              legalName
              phoneNumber
              email
              address
              taxNumber
              description
              representativeName
            }
          }
          total
          saleOffTotal
          cartStatus
          beforeSaleOffTotal
          cartTime
          membershipDiscountPercent
          membershipDiscountMoney
          description
          grandTotal
          cartDetails {
            id
            quantity
            products {
              id
              code
              upc
              name
              unit {
                id
                name
              }
            }
            quantity
            price
            total
            cashSaleOff
            saleOffType
            saleOffValue
            discountCustomer
            discountCustomerPercent
            totalPayment
          }
          cartFeedBacks {
            id
            author
            createdAt
            comment
          }
        }
      }
    }
  }
`;

export const getWorkOrdersProcess = gql`
  query getWorkOrdersProcess(
    $branchId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getWorkOrdersProcess(
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        ... on WorkOrdersProcess {
          id
          workOrders {
            id
            code
            name
            status
          }
          name
          processCode
          status
          reporter {
            id
            fullName
          }
          assignee {
            id
            fullName
          }
          fromTime
          toTime
          description
          subStatus
          products: workOrderProcessDetails {
            id
            productName
            productId
            productCode
            productImg
            unitId
            unitName
            smallGroupId
            smallGroup
            middleGroup
            middleGroupId
            largeGroupId
            largeGroup
            productType
            productQuantity
            productPrice
            costPrice
            total
            costTotal

            details: workOrderProcessPrdDetails {
              id
              productName
              productId
              productCode
              productImg
              unitId
              unitName
              smallGroupId
              productOrgQuantity
              smallGroup
              middleGroup
              middleGroupId
              largeGroupId
              largeGroup
              productType
              productQuantity
              productPrice
              costPrice
              total
              percentDepreciation
              costTotal
              realQuantity
            }
          }
        }
      }
    }
  }
`;

export const getInvoiceProcess = gql`
  query getInvoiceProcess(
    $branchId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getInvoiceProcess(
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on InvoiceProcessSteps {
          id
          code
          stepName
          invoices {
            id
            code
            title
            isB2B
            customers {
              id
              customerName
              urlAvatar
            }
          }
          processDate
          doneStock
          processStepInits {
            id
            productId
            productQuantity
            isGift
            stocks
          }
          processStatus
          description
          subStatus
          surcharges {
            id
            surchargeType
            amount
            description
          }
          total
          costTotal
          beforeSaleOffTotal
          saleOffTotal
          cashDiscount
          voucherDiscount
          couponDiscount
          moneyPointConvert
          membershipDiscountMoney
          grandTotalBeforeTax
          taxMoney
          grandTotal
          surchargeProductTotal
          surcharge
          discountCustomerTotal
        }
      }
    }
  }
`;

export const getOrderTemplateDetail = gql`
  query getOrderTemplate(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getOrderTemplate(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on OrderTemplate {
          templateName
          details: orderTemplateDetails {
            id
            products {
              id
              name
              code
              unit {
                id
                name
              }
              isSelling
            }
            quantity
            surchargeType
            saleOffType
            saleOffValue
            surchargeValue
            isGift
            discountCustomerPercent
          }
          customers: orderTemplateCustomers {
            id
            customers {
              id
              customerName
              address
              phoneNumber
            }
          }
        }
      }
    }
  }
`;

export const createOrderTemplate = gql`
  mutation createOrderTemplate($data: OrderTemplateInput!, $companyId: Int!) {
    data: createOrderTemplate(req: $data, companyId: $companyId) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const updateOrderTemplate = gql`
  mutation updateOrderTemplate(
    $data: OrderTemplateInput!
    $companyId: Int!
    $id: Int!
  ) {
    data: updateOrderTemplate(req: $data, companyId: $companyId, id: $id) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const updateInvoice = gql`
  mutation updateOrder(
    $data: InvoiceB2BUpdateInput!
    $branchId: Int!
    $incomeData: [IncomeExpenseInput]!
  ) {
    data: updateOrder(data: $data, branchId: $branchId, incomeData: $incomeData) {
      statusCode
      msgCode
      messageEn
      data{
        id
        ... on Invoices{
          ${invoiceInfo}
        }
      }
    }
  }
`;

export const changeInvoiceDate = gql`
  mutation changeInvoiceDate(
    $orderId: Int!
    $branchId: Int!
    $dateChange: Date!
  ) {
    data: changeInvoiceDate(
      orderId: $orderId
      branchId: $branchId
      dateChange: $dateChange
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const updateProductBOM = gql`
  mutation updateProductBOM(
    $data: [ProductBOMInput]!
    $companyId: Int!
    $productId: Int!
  ) {
    data: updateProductBOM(
      data: $data
      companyId: $companyId
      productId: $productId
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const createWorkOrder = gql`
  mutation createWorkOrder($req: WorkOrderInput!) {
    data: createWorkOrder(req: $req) {
      statusCode
      msgCode
      messageEn
      data {
        id
        ... on WorkOrders {
          id
          code
        }
      }
    }
  }
`;
export const addWorkOrderProcess = gql`
  mutation addWorkOrderProcess(
    $data: WorkOrderProcessInput!
    $workOrderId: Int!
  ) {
    data: addWorkOrderProcess(data: $data, workOrderId: $workOrderId) {
      statusCode
      msgCode
      messageEn
      data {
        id
      }
    }
  }
`;

export const updateWorkOrder = gql`
  mutation updateWorkOrder($req: WorkOrderInput!, $workOrderId: Int!) {
    data: updateWorkOrder(req: $req, workOrderId: $workOrderId) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const updateWorkOrderProcess = gql`
  mutation updateWorkOrderProcess(
    $data: WorkOrderProcessInput!
    $workOrderProcessId: Int!
    $incomeData: [IncomeExpenseInput]
  ) {
    data: updateWorkOrderProcess(
      data: $data
      workOrderProcessId: $workOrderProcessId
      incomeData: $incomeData
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const createUserB2B = gql`
  mutation createUserB2B(
    $companyId: Int!
    $phone: String!
    $fullName: String!
    $pwd: String!
  ) {
    data: createUserB2B(
      companyId: $companyId
      phone: $phone
      fullName: $fullName
      pwd: $pwd
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const updateCart = gql`
  mutation updateCart($companyId: Int!, $req: CartInput!) {
    data: updateCart(companyId: $companyId, req: $req) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const exportEInvoice = gql`
  mutation exportEInvoice(
    $branchId: Int!
    $orderId: Int!
    $cusReq: CustomerSInvoiceInput!
  ) {
    data: exportEInvoice(
      branchId: $branchId
      orderId: $orderId
      cusReq: $cusReq
    ) {
      statusCode
      data
    }
  }
`;

export const findIncomeExpenseElement = gql`
  query findIncomeExpenseElement(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findIncomeExpenseElement(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on IncomeExpenseElement {
          id
          code
          name
          accountantType
          description
        }
      }
    }
  }
`;

export const createIncomeExpenseElement = gql`
  mutation createIncomeExpenseElement(
    $companyId: Int!
    $req: IncomeExpenseElementInput!
  ) {
    data: createIncomeExpenseElement(companyId: $companyId, req: $req) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const findCustomerCompany = gql`
  query findCustomerCompany(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findCustomerCompany(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on CustomerCompany {
          id
          shortName
          legalName
          urlLogo
          phoneNumber
          email
          address
          taxNumber
          description
          code
          representativeName
          debtLimit
          maxDaysDebt
          overdueDebt
          totalDebt
          totalCredit
        }
      }
    }
  }
`;

export const createCustomerCompany = gql`
  mutation createCustomerCompany(
    $companyId: Int!
    $data: CustomerCompanyInput!
  ) {
    data: createCustomerCompany(companyId: $companyId, data: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const createManufactureCost = gql`
  mutation createManufactureCost(
    $companyId: Int!
    $productId: Int!
    $data: [ManufactureCostInput]
  ) {
    data: createManufactureCost(
      companyId: $companyId
      productId: $productId
      data: $data
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const updateReceiveBranch = gql`
  mutation updateReceiveBranch(
    $transferId: Int!
    $receiverBranch: Int!
    $transferBranch: Int!
  ) {
    data: updateReceiveBranch(
      transferId: $transferId
      receiverBranch: $receiverBranch
      transferBranch: $transferBranch
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const getPurchaseOrdersSummary = gql`
  query getPurchaseOrdersSummary($branchIds: [Int]!, $where: Json) {
    data: getPurchaseOrdersSummary(branchIds: $branchIds, where: $where) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const inventoryReceiverSummary = gql`
  query inventoryReceiverSummary($branchIds: [Int]!, $where: Json) {
    data: inventoryReceiverSummary(branchIds: $branchIds, where: $where) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const warehouseOutReceiptsSummaries = gql`
  query warehouseOutReceiptsSummaries($branchIds: [Int]!, $where: Json) {
    data: warehouseOutReceiptsSummaries(branchIds: $branchIds, where: $where) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const transferWarehouseSummaries = gql`
  query transferWarehouseSummaries($branchIds: [Int]!, $where: Json) {
    data: transferWarehouseSummaries(branchIds: $branchIds, where: $where) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const workProcessProduct = gql`
  query workProcessProduct(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: workProcessProduct(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const workOrderExternalCost = gql`
  query workOrderExternalCost(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: workOrderExternalCost(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const volumeMaterialsUsed = gql`
  query volumeMaterialsUsed(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: volumeMaterialsUsed(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const workProcessMaterial = gql`
  query workProcessMaterial(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: workProcessMaterial(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const volumeProductCompleted = gql`
  query volumeProductCompleted(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: volumeProductCompleted(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const workOrderProcessByDate = gql`
  query workOrderProcessByDate(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: workOrderProcessByDate(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const workProcessProductQty = gql`
  query workProcessProductQty(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: workProcessProductQty(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const employeeSaleTargetByEmployee = gql`
  query employeeSaleTargetByEmployee(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
    $employeeId: Int!
  ) {
    data: employeeSaleTargetByEmployee(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      employeeId: $employeeId
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const employeeSaleTarget = gql`
  query employeeSaleTarget(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: employeeSaleTarget(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }

    dataCount: countEmployeeSaleTarget(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const employeeRevenueReport = gql`
  query employeeRevenueReport(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: employeeRevenueReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }

    dataCount: employeeRevenueReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const workOrderProcessMaterialDetail = gql`
  query workOrderProcessMaterialDetail(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
    $limit: Int
    $where: Json
    $skip: Int
  ) {
    data: workOrderProcessMaterialDetail(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      where: $where
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on ProcessProductDetails {
          id
          prdId
          prdCode
          prdName
          qty
          unitName
          realQty
          qtyDepreciation
        }
      }
      totalCount
    }
  }
`;

export const workOrderProcessProductDetail = gql`
  query workOrderProcessProductDetail(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
    $limit: Int
    $where: Json
    $skip: Int
  ) {
    data: workOrderProcessProductDetail(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      limit: $limit
      where: $where
      skip: $skip
    ) {
      list {
        id
        ... on ProcessProductDetails {
          prdId
          prdCode
          prdName
          unitName
          qty
          realQty
        }
      }
      totalCount
    }
  }
`;

export const getInvoiceSummaryByCusCom = gql`
  query getInvoiceSummaryByCusCom(
    $companyId: Int!
    $fromDate: Date!
    $toDate: Date!
    $cusComId: Int!
  ) {
    data: getInvoiceSummaryByCusCom(
      companyId: $companyId
      fromDate: $fromDate
      toDate: $toDate
      cusComId: $cusComId
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const findSearchHistory = gql`
  query findSearchHistory(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findSearchHistory(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on SearchHistory {
          id
          keySearch
          model
          searchTime
        }
      }
    }
  }
`;

export const findIncomeExpensePaymentDetail = gql`
  query findIncomeExpensePaymentDetail(
    $branchId: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findIncomeExpensePaymentDetail(
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on IncomeExpensePaymentDetail {
          id
          incomeExpense {
            id
            code
            startDate
            accountantType
            endDate
            receiver
            transfer
            bookNo
            codeNumber
            elementCode
            elementName
            description
            address
          }
          currencyUnit
          originalCurrency
          exchangePrice
          totalPayment
          isCashPayment
          cardType
          cardNumber
        }
      }
      totalCount
    }
  }
`;

export const findIncomeExpensePayment = gql`
  query findIncomeExpensePayment(
    $branchId: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findIncomeExpensePayment(
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on IncomeExpensePayment {
          id
          incomeExpense {
            id
            code
            startDate
            endDate
            receiver
            transfer
            bookNo
            codeNumber
            elementCode
            elementName
            description
            address
          }
          debtAcc {
            id
            companyCircularCategory {
              id
              code
              name
              accountantType
            }
          }
          availableAcc {
            id
            companyCircularCategory {
              id
              code
              name
              accountantType
            }
          }
          totalPayment
          paymentTime
          isManual
          receiver
          transfer
          transferCode
          receiverCode
        }
      }
      totalCount
    }
  }
`;

const salesTargets = `
    id
    employees {
      id
      fullName
    }
    smallGroups{
      id
      name
      code
      urlImage
    }
    middleGroups{
      id
      code
      name
      urlImage
    }
    largeGroups{
      code
      id 
      name
      urlImage
    }
    department {id name code }
    customerGroup{id groupName}
    products {id name code unit { name } }
    branches {id branchName}
    salesChannels{ id name isOnline}
    target
    quantityTarget

`;
export const findSalesTargetGroup = gql`
query findSalesTargetGroup(
  $companyId: Int!
  $where: Json
  $orderBy: String
  $limit: Int
  $skip: Int
) {
  data: findSalesTargetGroup(
    companyId: $companyId
    where: $where
    orderBy: $orderBy
    limit: $limit
    skip: $skip
  ) {
    list {
      id
      ... on SalesTargetGroup {
        id
        name
        content
        fromDate
        toDate
        totalTarget
        salesTargets{
          ${salesTargets}
        }
      }
    }
    totalCount
  }
}
`;

export const findSalesTarget = gql`
  query findSalesTarget(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findSalesTarget(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on SalesTarget {
            ${salesTargets}
        }
      }
      totalCount
    }
  }
`;

export const findDepartment = gql`
  query findDepartment(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findDepartment(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      list {
        id
        ... on Department {
          id
          deptRef {
            id
            code
            name
          }
          code
          name
          manager {
            id
            employeeCode
            fullName
          }
          deputy {
            id
            employeeCode
            fullName
          }
          description
          departmentBranches {
            id
            branches {
              id
              branchName
              branchCode
            }
          }
          departmentEmployees {
            id
            employees {
              id
              fullName
              employeeCode
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const createDepartment = gql`
  mutation createDepartment($companyId: Int!, $data: DepartmentInput!) {
    data: createDepartment(companyId: $companyId, data: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const createSaleTarget = gql`
  mutation createSaleTarget($companyId: Int!, $data: [SaleTargetInput]!) {
    data: createSaleTarget(companyId: $companyId, data: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const createSaleTargetGroup = gql`
  mutation createSaleTargetGroup(
    $companyId: Int!
    $data: [SaleTargetGroupInput]!
  ) {
    data: createSaleTargetGroup(companyId: $companyId, data: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const addServiceReceipt = gql`
  mutation addServiceReceipt(
    $branchId: Int!
    $data: ServiceReceiptInput!
    $incomeData: [IncomeExpenseInput]
  ) {
    data: addServiceReceipt(
      branchId: $branchId
      data: $data
      incomeData: $incomeData
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const getPermissionSchema = gql`
  query getPermissionSchema {
    data: getPermissionSchema {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const findAsset = gql`
  query findAsset(
    $branchIds: [Int]!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findAsset(
      branchIds: $branchIds
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on Asset {
          name
          code
          description
          serialNumber
          urlImage
          assetType {
            id
            code
            name
          }
          receiptDate
          usedDate
          useMonth
          damageDate
          assetStatus
          price
          employees {
            id
            employeeCode
            fullName
          }
          assetHistories {
            id
            date
            historyType
            description
            employees {
              id
              fullName
            }
          }
        }
      }
    }
  }
`;

export const findCredit = gql`
  query findCredit($companyId: Int!, $where: Json, $orderBy: String) {
    data: findCredit(companyId: $companyId, where: $where, orderBy: $orderBy) {
      totalCount
      list {
        id
        ... on Credit {
          id
          customers {
            id
            customerName
          }
          suppliers {
            id
          }
          branches {
            id
            branchName
            branchCode
          }
          transfer
          receiver
          creditDate
          money
          description
          refId
        }
      }
    }
  }
`;

export const createCredit = gql`
  mutation createCredit(
    $companyId: Int!
    $data: CreditInput!
    $incomeData: [IncomeExpenseInput]
  ) {
    data: createCredit(
      companyId: $companyId
      data: $data
      incomeData: $incomeData
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const inventoryWarehouseReport = gql`
  query inventoryWarehouseReport(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: inventoryWarehouseReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const findPrintTemplate = gql`
  query findPrintTemplate(
    $branchId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findPrintTemplate(
      branchId: $branchId
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      totalCount
      list {
        id
        ... on PrintTemplate {
          id
          templateType
          templateSize
          template
          templateFooter
          templateItem
          style
        }
      }
    }
  }
`;

export const createPrintTemplate = gql`
  mutation createPrintTemplate($branchId: Int!, $data: PrintTemplateInput!) {
    data: createPrintTemplate(branchId: $branchId, req: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const findAssetType = gql`
  query findAssetType($companyId: Int!, $where: Json, $orderBy: String) {
    data: findAssetType(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
    ) {
      id
      code
      description
      name
    }
  }
`;

export const createAsset = gql`
  mutation createAsset(
    $branchId: Int!
    $data: [AssetInput]!
    $incomeData: [IncomeExpenseInput]
  ) {
    data: createAsset(
      branchId: $branchId
      data: $data
      incomeData: $incomeData
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const createAssetType = gql`
  mutation createAssetType($companyId: Int!, $data: AssetTypeInput!) {
    data: createAssetType(companyId: $companyId, req: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const getAssetReport = gql`
  query getAssetReport($branchIds: [Int]!, $fromDate: Date!, $toDate: Date!) {
    data: getAssetReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const customerDebtReport = gql`
  query customerDebtReport(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: customerDebtReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const personInChargeReport = gql`
  query personInChargeReport(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: personInChargeReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const branchesDebtReport = gql`
  query branchesDebtReport(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: branchesDebtReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const customerProductSaleReport = gql`
  query customerProductSaleReport(
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: customerProductSaleReport(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const productInventoryByStock = gql`
  query productInventoryByStock(
    $where: Json
    $branchIds: [Int]!
    $orderBy: String
  ) {
    data: productInventoryByStock(
      branchIds: $branchIds
      orderBy: $orderBy
      where: $where
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const saleProductByStock = gql`
  query saleProductByStock(
    $where: Json
    $branchIds: [Int]!
    $orderBy: String
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: saleProductByStock(
      branchIds: $branchIds
      orderBy: $orderBy
      fromDate: $fromDate
      toDate: $toDate
      where: $where
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const saleProductByChannels = gql`
  query saleProductByChannels(
    $where: Json
    $branchIds: [Int]!
    $orderBy: String
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: saleProductByChannels(
      branchIds: $branchIds
      orderBy: $orderBy
      fromDate: $fromDate
      toDate: $toDate
      where: $where
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const saleProductByOrderType = gql`
  query saleProductByOrderType(
    $where: Json
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: saleProductByOrderType(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      where: $where
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const exportProductByType = gql`
  query saleProductByOrderType(
    $where: Json
    $branchIds: [Int]!
    $fromDate: Date!
    $toDate: Date!
  ) {
    data: exportProductByType(
      branchIds: $branchIds
      fromDate: $fromDate
      toDate: $toDate
      where: $where
    ) {
      statusCode
      msgCode
      message
      list: data
      totalCount
    }
  }
`;

export const getShopIds = gql`
  query getShopIds {
    data: getShopIds {
      shopId
      platformType
      branches {
        id
        branchCode
      }
    }
  }
`;

export const getShopInfo = gql`
  mutation getShopInfo($shopId: String!) {
    getShopInfo(shopId: $shopId) {
      extraInfo
      data
      statusCode
    }
  }
`;

export const addProductShopee = gql`
  mutation addProductShopee($shopId: String!, $req: ProductReqInput!) {
    data: addProductShopee(shopId: $shopId, req: $req) {
      statusCode
      msgCode
      data
    }
  }
`;

export const syncProductToApp = gql`
  mutation syncProductToApp($shopId: String!) {
    data: syncProductToApp(shopId: $shopId) {
      statusCode
      msgCode
      data
      extraInfo
    }
  }
`;

export const getProductSyncApp = gql`
  query getProductSyncApp(
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: getProductSyncApp(
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      totalCount
      list {
        id
        ... on EcommerceItems {
          id
          ecoId
          sku
          prdName
          shopId
          EcommerceName
        }
      }
    }
  }
`;

export const getCategory = gql`
  query getCategory($shopId: String!) {
    data: getCategory(shopId: $shopId) {
      extraInfo
      data
      statusCode
    }
  }
`;

export const getCategoryRecommend = gql`
  query getCategoryRecommend($shopId: String!) {
    data: getCategoryRecommend(shopId: $shopId) {
      extraInfo
      data
      statusCode
    }
  }
`;

export const getAttributes = gql`
  query getAttributes($shopId: String!) {
    data: getAttributes(shopId: $shopId) {
      extraInfo
      data
      statusCode
    }
  }
`;

export const getAttributesRecommend = gql`
  query getAttributesRecommend($shopId: String!) {
    data: getAttributesRecommend(shopId: $shopId) {
      extraInfo
      data
      statusCode
    }
  }
`;

// export const getBrands = gql`
//   query getBrands($shopId: String!) {
//     data: getBrands(shopId: $shopId) {
//       extraInfo
//       data
//       statusCode
//     }
//   }
// `;

export const getShippingLimit = gql`
  query getShippingLimit($shopId: String!) {
    data: getShippingLimit(shopId: $shopId) {
      extraInfo
      data
      statusCode
    }
  }
`;

export const getChannelShipping = gql`
  query getChannelShipping($shopId: String!) {
    data: getChannelShipping(shopId: $shopId) {
      extraInfo
      data
      statusCode
    }
  }
`;

export const findNotice = gql`
  query findNotice(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findNotice(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      totalCount
      list {
        ... on Notice {
          id
          title
          createDate
          content
          images
          employees {
            id
            fullName
          }
          expiredTime
          branches {
            id
            branchName
          }
        }
      }
    }
  }
`;

export const createNotice = gql`
  mutation createNotice($companyId: Int!, $data: NoticeInput!) {
    data: createNotice(companyId: $companyId, req: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const findEmployeeFundType = gql`
  query findEmployeeFundType(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findEmployeeFundType(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      totalCount
      list {
        ... on EmployeeFundType {
          id
          fundCode
          fundName
          description
        }
      }
    }
  }
`;

export const findEmployeeFund = gql`
  query findEmployeeFund(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findEmployeeFund(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      totalCount
      list {
        ... on EmployeeFund {
          id
          amount
          status
          description
          date
          referenceCode
          employeeFundType {
            id
            fundCode
            fundName
          }
          receiverEmployee {
            id
            fullName
          }
          receiverCustomer {
            id
            customerName
            customerCode
          }
          receiverSupplier {
            id
            supplierName
            supplierCode
          }
          receiverBranch {
            id
            branchName
          }
          transferEmployee {
            id
            fullName
          }
          transferCustomer {
            id
            customerName
            customerCode
          }
          transferSupplier {
            id
            supplierName
            supplierCode
          }
          transferBranch {
            id
            branchName
          }
        }
      }
    }
  }
`;

export const createEmployeeFund = gql`
  mutation createEmployeeFund($companyId: Int!, $data: EmployeeFundInput!) {
    data: createEmployeeFund(companyId: $companyId, req: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const createEmployeeFundType = gql`
  mutation createEmployeeFundType(
    $companyId: Int!
    $data: EmployeeFundTypeInput!
  ) {
    data: createEmployeeFundType(companyId: $companyId, req: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

///

export const findCustomerCare = gql`
  query findCustomerCare(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findCustomerCare(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      totalCount
      list {
        ... on CustomerCare {
          id
          type {
            name
            code
            id
          }
          employees {
            id
            fullName
            employeeCode
          }
          status
          customers {
            id
            customerName
          }
          title
          code
          images
          description
          beginTime
          endTime
          customerCareResults {
            id
            content
            type
            dateTime
            status
            description
            files
            employees {
              id
              fullName
            }
          }
        }
      }
    }
  }
`;

export const createCustomerCare = gql`
  mutation createCustomerCare($companyId: Int!, $data: CustomerCareInput!) {
    data: createCustomerCare(companyId: $companyId, req: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

///

export const findCustomerCareType = gql`
  query findCustomerCareType(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findCustomerCareType(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      totalCount
      list {
        ... on CustomerCareType {
          id
          code
          name
          description
        }
      }
    }
  }
`;

export const createCustomerCareType = gql`
  mutation createCustomerCareType(
    $companyId: Int!
    $data: CustomerCareTypeInput!
  ) {
    data: createCustomerCareType(companyId: $companyId, req: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

export const createCustomerCareResult = gql`
  mutation createCustomerCareResult(
    $companyId: Int!
    $customerCareId: Int!
    $req: [CustomerCareResultInput]!
  ) {
    data: createCustomerCareResult(
      companyId: $companyId
      customerCareId: $customerCareId
      req: $req
    ) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

////

export const findCampaign = gql`
  query findCampaign(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findCampaign(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      totalCount
      list {
        ... on Campaign {
          id
          title
          name
          description
          startDate
          endDate
          branches {
            id
            branchName
          }
          type
          rules
        }
      }
    }
  }
`;

export const createCampaign = gql`
  mutation createCampaign($companyId: Int!, $data: CampaignInput!) {
    data: createCampaign(companyId: $companyId, req: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;

///
export const findCustomerDebt = gql`
  query findCustomerDebt(
    $companyId: Int!
    $where: Json
    $orderBy: String
    $limit: Int
    $skip: Int
  ) {
    data: findCustomerDebt(
      companyId: $companyId
      where: $where
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      totalCount
      list {
        ... on CustomerDebt {
          customers {
            id
          }
          debtDate
          debtType
          debtStatus
          staffInCharge {
            id
            fullName
          }
          invoices {
            id
            code
          }
          refCode
          description
        }
      }
    }
  }
`;

export const createCustomerDebt = gql`
  mutation updateCustomerDebt($companyId: Int!, $data:CustomerDebtInput!) {
    data: updateCustomerDebt(companyId: $companyId, req: $data) {
      statusCode
      msgCode
      messageEn
    }
  }
`;
