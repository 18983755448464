<template>
  <div class="data-list">
    <div
      class="bulk-actions align-items-center flex flex-row"
      v-if="selectedList.length"
    >
      <p class="mr-3">{{ selectedList.length }} đã chọn</p>
      <b-dropdown text="Chọn chức năng" class="m-md-2" variant="white">
        <b-dropdown-item
          @click="action.click(selectedList)"
          :key="action.key"
          v-for="action in bulkActions"
        >
          <i :class="action.icon" />
          {{ action.name }}
        </b-dropdown-item>
      </b-dropdown>
      <div class="flex flex-1" />
      <button
        class="btn btn-basic text-white"
        @click="
          selecteds = {};
          onSelected();
        "
      >
        <i class="fa fa-times" />
        Huỷ
      </button>
    </div>
    <div class="d-block d-md-none">
      <input
        maxlength="200"
        class="form-control"
        v-model="searchKey"
        style="border-radius: 0"
        placeholder="Tìm kiếm, thêm [/] để tìm chính xác theo mã"
        @keyup.enter="search()"
      />
    </div>
    <div
      class="data-grid d-block d-md-none p-2 overflow-auto"
      style="height: calc(100lvh - 125px)"
    >
      <div class="box p-2 mb-2" v-for="(row, index) in dataSource" :key="index">
        <div class="box-header d-flex p-1 align-items-center gap-1">
          <span
            v-if="fields[0].checkbox != null"
            @click="fields[0].click && fields[0].click(row)"
          >
            <check-box :value="fields[0].checkbox(row)" />
          </span>
          <div
            class="flex-fill d-flex gap-1 align-items-center"
            @click="
              showDetail[row.id] = !showDetail[row.id];
              $forceUpdate();
            "
          >
            <div
              class="flex-fill"
              v-html="
                renderHeaderMobile
                  ? renderHeaderMobile(row)
                  : fields[headerRenderIndex || 0].showData
                  ? fields[headerRenderIndex || 0].showData(row)
                  : row[
                      fields[headerRenderIndex || 0].key
                        ? fields[headerRenderIndex || 0].key
                        : field
                    ]
              "
            />
            <button
              v-if="
                fields[headerRenderIndex] && fields[headerRenderIndex].click
              "
              class="btn btn-outline-primary btn-sm"
              @click="fields[headerRenderIndex].click(row)"
            >
              Chọn
            </button>
            <i v-if="showDetail[row.id]" class="fa fa-chevron-up" />
            <i v-if="!showDetail[row.id]" class="fa fa-chevron-down" />
          </div>
        </div>

        <div class="box-body p-0" v-if="showDetail[row.id]">
          <div
            class="box-item flex flex-row align-items-center"
            :class="
              typeof field.className == 'function'
                ? field.className(row)
                : field.className
            "
            v-for="(field, idx) in fieldsShow"
            :key="(field.key ? field.key : field) + '_' + idx"
            @click="field.click && field.click(row)"
          >
            <div class="flex flex-1">
              <label
                class="text nobreak m-0"
                v-html="field.title || field.key || field"
              />
            </div>
            <div class="text-right">
              <span v-if="field && field.link">
                <strong>
                  <router-link :to="field.link(row)">
                    <span
                      v-html="
                        field.showData
                          ? field.showData(row)
                          : row[field.key ? field.key : field]
                      "
                    />
                  </router-link>
                </strong>
              </span>
              <span
                v-else
                v-html="
                  field.showData
                    ? field.showData(row)
                    : row[field.key ? field.key : field]
                "
              ></span>
            </div>
          </div>
        </div>

        <div
          class="box-footer text-right"
          v-if="rowActions && rowActions.length && showDetail[row.id]"
        >
          <span v-for="(action, index) in rowActions" :key="index">
            <button
              v-if="typeof action.isShow !== 'function' || action.isShow(row)"
              @click="action.click && action.click(row)"
              :class="'btn btn-sm btn-' + action.color"
              class="ml-2 mb-2"
              :disabled="action.isLoading && action.isLoading(row)"
            >
              <i
                :class="
                  action.isLoading && action.isLoading(row)
                    ? 'fa fa-spinner fa-pulse fa-spin'
                    : action.icon
                "
              />
              {{ action.name }}
            </button>
          </span>
        </div>
      </div>
      <div
        v-if="!dataSource.length && !loading"
        class="flex box pt-5 align-items-center justify-content-center"
        style="height: calc(100% - 80px)"
      >
        <img width="100px" style="opacity: 0.7" src="/images/box.png" />
        <p class="font-16 p-3 bold">
          <i class="mdi mdi-emoticon-sad" /> Không có dữ liệu
        </p>
      </div>
    </div>
    <div
      class="d-none d-md-block data-table table-bordere"
      :style="{ height: height }"
      :class="{ 'scroll-left': isScrollLeft, 'scroll-right': isScrollRight }"
      @scroll="onScrollTable"
      ref="scrollTableHorizon"
    >
      <table class="table">
        <thead>
          <tr>
            <th class="sticky pl-12px" v-if="bulkActions && bulkActions.length">
              <check-box v-model="isCheckAll" @change="onSelectAll()" />
            </th>
            <th
              :class="
                (typeof field.className == 'function'
                  ? field.className()
                  : field.className) +
                ' ' +
                (advancedSearch ? 'pb-0' : '')
              "
              v-for="(field, idx) in fieldsShow"
              :key="(field.key ? field.key : field) + '_' + idx"
              :style="{
                'min-width':
                  widthsConfig[field.key] || field.minWidth || field.width,
                width: widthsConfig[field.key] || field.width,
                'max-width': widthsConfig[field.key] || field.maxWidth,
                verticalAlign: 'top',
              }"
              class="nobreak"
            >
              <div
                @click="!field.noSort && changeSort(field.key)"
                class="d-flex align-items-center"
              >
                <span class="sort" v-if="!field.noSort">
                  <i
                    class="fa fa-sort-up"
                    :class="{
                      active:
                        sort.split('_')[0] == field.key &&
                        sort.split('_')[1] == 'asc',
                    }"
                  />
                  <i
                    class="fa fa-sort-down"
                    :class="{
                      active:
                        sort.split('_')[0] == field.key &&
                        sort.split('_')[1] == 'desc',
                    }"
                  />
                </span>
                <span
                  class="text flex-fill font-12"
                  v-html="field.title || field.key || field"
                >
                </span>
              </div>
              <div v-if="advancedSearch">
                <div class="my-1" v-if="field.searchKey || field.searchType">
                  <div
                    class="d-flex miw-150px"
                    v-if="field.searchType == 'number'"
                  >
                    <div
                      class="position-absolute m-1 h-32px"
                      style="background: #ddd; border-radius: 4px"
                    >
                      <select
                        style="background: transparent; height: 23px"
                        class="border-0 font-12 bold px-1"
                        v-model="field.searchOption"
                        @change="
                          searchOption(field);
                          search();
                        "
                      >
                        <option :value="undefined">X</option>
                        <option value="gt">{{ ">" }}</option>
                        <option value="gte">{{ ">=" }}</option>
                        <option value="lt">{{ "<" }}</option>
                        <option value="lte">{{ "<=" }}</option>
                        <option value="eq">{{ "==" }}</option>
                      </select>
                    </div>
                    <money-input
                      class="form-control form-control-sm text-right"
                      :placeholder="`Tìm kiếm ${field.title}`"
                      @change="
                        searchOption(field);
                        search();
                      "
                      v-model="field.searchValue"
                      :disabled="!field.searchOption"
                    />
                  </div>
                  <date-picker
                    v-else-if="field.searchType == 'date'"
                    :placeholder="`Tìm kiếm ${field.title}`"
                    v-model="searchObj[field.key]"
                    @change="search()"
                    class="sm w-100"
                    style="min-width: 150px"
                  />

                  <date-picker
                    range
                    :placeholder="`Tìm kiếm ${field.title}`"
                    v-else-if="field.searchType == 'date-range'"
                    v-model="field.searchValue"
                    class="form-control-sm w-100"
                    :shortcuts="$shortcutDateRange"
                    style="min-width: 200px"
                    @change="onChangeDateRange(field)"
                  />

                  <auto-complete
                    style="text-transform: none"
                    v-model="searchObj[field.key + '_in']"
                    :placeholder="`Tìm kiếm ${field.title}`"
                    :list="field.searchList"
                    v-else-if="field.searchType == 'multi-select'"
                    @change="
                      searchObj[field.key + '_in'] =
                        searchObj[field.key + '_in'].length > 0
                          ? searchObj[field.key + '_in']
                          : undefined;
                      search();
                    "
                    :multi="true"
                    :small="true"
                    class="w-100"
                  />

                  <select
                    v-model="searchObj[field.key]"
                    :placeholder="`Tìm kiếm ${field.title}`"
                    v-else-if="field.searchType == 'select'"
                    @change="search()"
                    class="form-control form-control-sm"
                  >
                    <option :value="undefined" style="font-style: italic">
                      Tất cả
                    </option>
                    <option
                      v-for="o in field.searchList"
                      :key="o.id"
                      :value="o.id"
                    >
                      {{ o.name }}
                    </option>
                  </select>

                  <input
                    v-else
                    class="form-control form-control-sm"
                    :placeholder="`Tìm kiếm ${field.title}`"
                    @change="search()"
                    v-model="searchObj[field.searchKey]"
                    :name="field.key"
                  />
                </div>
              </div>

              <p
                class="bold font-14 pb-1"
                v-if="totals && totals[field.key] !== undefined"
              >
                {{
                  field.showData
                    ? field.showData(totals)
                    : totals[field.key ? field.key : field]
                }}
              </p>
            </th>
            <th
              class="action text-center"
              v-if="rowActions && rowActions.length"
              style="width: 10px"
            />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in dataSource"
            :key="index"
            :class="{ selected: selecteds[row.id], disabled: row._disabled }"
          >
            <td class="sticky pl-12px" v-if="bulkActions && bulkActions.length">
              <check-box v-model="selecteds[row.id]" @change="onSelected()" />
            </td>
            <td
              :class="
                typeof field.className == 'function'
                  ? field.className(row)
                  : field.className
              "
              :style="{
                width: widthsConfig[field.key] || row.width,
                backgroundColor:
                  sort.split('_')[0] == field.key
                    ? '#eee'
                    : hoverColumn == field.key
                    ? '#eee'
                    : '',
                transition: 'all 0.1s linear 0s',
              }"
              v-for="(field, idx) in fieldsShow"
              :key="(field.key ? field.key : field) + '_' + idx"
              @click="field.click && field.click(row)"
              v-b-tooltip.hover
              :title="
                typeof field.tooltip == 'function'
                  ? field.tooltip(row)
                  : field.tooltip
              "
              @mouseenter="hoverColumn = field.key"
              @mouseleave="hoverColumn = ''"
            >
              <span v-if="field && field.link" class="bold">
                <router-link :to="field.link(row)">
                  <span
                    v-html="
                      field.showData
                        ? field.showData(row)
                        : row[field.key ? field.key : field]
                    "
                  />
                </router-link>
              </span>
              <span v-else-if="field.checkbox != null">
                <check-box :value="field.checkbox(row)" />
              </span>
              <span
                v-else
                v-html="
                  field.showData
                    ? field.showData(row)
                    : row[field.key ? field.key : field]
                "
              ></span>
            </td>
            <td class="action" v-if="rowActions && rowActions.length">
              <span v-for="(action, index) in rowActions" :key="index">
                <button
                  v-b-tooltip.hover
                  :title="action.name"
                  v-if="
                    typeof action.isShow !== 'function' || action.isShow(row)
                  "
                  @click="action.click && action.click(row)"
                  :class="'btn btn-sm btn-' + action.color"
                  :disabled="action.isLoading && action.isLoading(row)"
                >
                  <i
                    :class="
                      action.isLoading && action.isLoading(row)
                        ? 'fa fa-spinner fa-pulse fa-spin'
                        : action.icon
                    "
                  />
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="dataSource.length == 0"
        class="d-flex flex-column align-items-center justify-content-center"
        :style="{
          height: $refs.scrollTableHorizon
            ? $refs.scrollTableHorizon.clientHeight - 90 + 'px'
            : '200px',
          width: $refs.scrollTableHorizon
            ? $refs.scrollTableHorizon.clientWidth - 90 + 'px'
            : '200px',
        }"
      >
        <img width="100px" src="/images/box.png" />
        <p class="font-16 p-3 bold">
          <i class="mdi mdi-emoticon-sad" /> Không có dữ liệu
        </p>
      </div>
    </div>
    <div
      class="d-flex align-items-center list-footer py-0 gap-1"
      style="z-index: 3"
    >
      <div class="d-flex flex-1 align-items-center">
        <div class="flex-1" v-if="!noPaging">
          <p class="left-footer font-12">
            <select v-model="limit" @change="changeLimit()">
              <option v-for="l in $allLimit" :key="l">{{ l }}</option>
            </select>
            <span class="d-none d-md-inline-block">
              {{
                $t("common.from_page_to_page", {
                  from: (page - 1) * limit + 1,
                  to: page * limit > total ? total : page * limit,
                  of: total,
                })
              }}
            </span>
          </p>
        </div>
        <Pagination
          :total="totalPage"
          :currentPage="page"
          @changePage="onChangePage"
        />
      </div>

      <div class="d-flex align-items-center">
        <check-box
          class="d-none d-md-inline-block"
          title="Tìm nâng cao"
          v-model="advancedSearch"
        />
        <div
          class="flex-fill mw-300px d-none d-md-flex"
          style="position: relative"
          v-if="!noSearch && !advancedSearch"
        >
          <div
            class="position-absolute bg-white border w-100 rounded shadow"
            style="bottom: 32px"
            v-if="showDrop && oldKeySearchs.length > 0"
          >
            <span
              v-for="k in oldKeySearchs.filter((a) => a.keySearch)"
              :key="k.keySearch"
              class="m-1 p-1 border font-12 bg-white pointer d-inline-block rounded-lg"
              @click="
                searchKey = k.keySearch;
                search();
              "
            >
              <i class="fa fa-history" />
              {{ k.keySearch }}
            </span>
          </div>
          <input
            maxlength="200"
            class="form-control font-12"
            :class="showDrop ? 'w-300px' : 'w-100'"
            v-model="searchKey"
            placeholder="Tìm kiếm, thêm [/] để tìm chính xác theo mã"
            @keyup.enter="search()"
            @focus="showDrop = true"
            @click="showDrop = true"
            @blur="onBlurSearch()"
            :disabled="advancedSearch"
          />
          <i
            v-if="searchKey"
            class="fa fa-times remove-search"
            @click="
              searchKey = '';
              search();
            "
          />
        </div>
        <b-dropdown
          size="sm"
          text="..."
          no-caret
          variant="basic"
          class="m-2"
          right
        >
          <b-dropdown-item-button @click="exportAll()" v-if="!disabledExport">
            Xuất ra .xlsx
          </b-dropdown-item-button>

          <b-dropdown-item-button @click="openConfig()">
            Cài đặt cột
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
    </div>
    <div v-if="loading" class="loading">
      <div>
        <div class="loading-spin">
          <i />
        </div>
        <p>Đang tải</p>
      </div>
    </div>
    <data-table-config :tableName="name" ref="config" />
  </div>
</template>

<script>
import Pagination from "./Pagination";
import { scrollMixin } from "../services/mixins";
import { printTableExcel } from "../services/print";
import DataTableConfig from "./DataTableConfig.vue";
export default {
  components: {
    Pagination,
    DataTableConfig,
  },
  name: "DataTable",
  props: [
    "disabledExport",
    "fields",
    "rowActions",
    "nameFunction",
    "paramsQuery",
    "keySearch",
    "includeInactive",
    "autoLoad",
    "sortMode",
    "noSearch",
    "afterLines",
    "height",
    "bulkActions",
    "noPaging",
    "formatData",
    "searchLabel",
    "from",
    "exactCode",
    "name",
    "totals",
    "headerRenderIndex",
    "noExport",
    "filterLocal",
    "renderHeaderMobile",
  ],
  computed: {
    fieldsShow() {
      return this.fields.filter(
        (i) =>
          (!i.noShow || (i.noShow && !i.noShow())) &&
          this.viewsConfig[i.key] !== false
      );
    },
  },
  data() {
    return {
      hoverColumn: "",
      advancedSearch: false,
      sort: "id_desc",
      dataSource: [],
      page: 1,
      limit: 25,
      showDrop: false,
      total: 0,
      totalPage: 1,
      oldKeySearchs: [],
      loading: false,
      searchKey: "",
      cardView: false,
      selecteds: {},
      selectedList: [],
      isCheckAll: 0,

      searchObj: {},
      viewsConfig: {},
      widthsConfig: {},
      showDetail: {},
    };
  },
  mixins: [scrollMixin],
  methods: {
    openConfig() {
      this.$refs.config.open({ columns: this.fields }).then(() => {
        this.getConfig();
      });
    },
    getConfig() {
      const d = localStorage.getItem("config-" + this.name);
      if (d) {
        const data = JSON.parse(d);
        this.viewsConfig = data.views || {};
        this.widthsConfig = data.widths || {};
        this.$forceUpdate();
      }
    },
    onChangeDateRange(field) {
      if (field.searchValue && field.searchValue[0] && field.searchValue[1]) {
        this.searchObj[`${field.key}_lte`] =
          this.$formatDate(field.searchValue[1]) + "T23:59:59";
        this.searchObj[`${field.key}_gte`] =
          this.$formatDate(field.searchValue[0]) + "T00:00:00";
      } else {
        this.searchObj[`${field.key}_lte`] = undefined;
        this.searchObj[`${field.key}_gte`] = undefined;
      }

      this.search();
    },
    searchOption(field) {
      this.searchObj[`${field.key}_`] = undefined;
      this.searchObj[`${field.key}_lte`] = undefined;
      this.searchObj[`${field.key}_gte`] = undefined;
      this.searchObj[`${field.key}_lt`] = undefined;
      this.searchObj[`${field.key}_gt`] = undefined;
      this.searchObj[`${field.key}_eq`] = undefined;

      if (field.searchOption) {
        this.searchObj[`${field.key}_${field.searchOption}`] =
          field.searchValue || 0;
      }
    },
    exportAll() {
      let inputs = [
        { title: "Tên file", key: "filename", value: "", width: "100%" },
      ];

      const config = {
        title: "Xuất excel tất cả",
        size: "sm",
        allowClose: true,
      };
      this.$root.formPopup.sending = false;
      this.$root.formPopup.openPopup(inputs, config, async (form) => {
        this.$root.formPopup.closePopup();
        var list = [];
        var limit = 30;
        for (let i = 1; i <= Math.ceil(this.total / limit); i = i + 1) {
          var res = await this.getData(i, limit, "id_desc", true);
          list = list.concat(res);
        }
        if (this.totals) {
          list = list.concat(this.totals || {});
        }
        console.log(list);
        printTableExcel(
          list,
          this.fields,
          form.filename || this.nameFunction || "danh-sach"
        );
      });
    },
    onBlurSearch() {
      setTimeout(() => {
        this.showDrop = false;
      }, 300);
    },
    onSelectAll() {
      if (this.isCheckAll) {
        this.dataSource.forEach((i) => {
          this.selecteds[i.id] = true;
        });
      } else {
        this.dataSource.forEach((i) => {
          this.selecteds[i.id] = false;
        });
      }
      this.onSelected();
    },
    onSelected() {
      const selecteds = {};
      const selectedList = [];
      Object.keys(this.selecteds).map((i) => {
        if (this.selecteds[i]) {
          selecteds[i] = true;
          selectedList.push(i);
        }
      });
      this.selecteds = selecteds;
      this.selectedList = selectedList;
      this.isCheckAll = 2;
      var totalCheck = 0;
      totalCheck = this.dataSource.reduce(
        (a, b) => (this.selecteds[b.id] ? a + 1 : a),
        0
      );
      if (totalCheck == this.dataSource.length) {
        this.isCheckAll = 1;
      }
      if (totalCheck == 0) {
        this.isCheckAll = 0;
      }
    },
    clearSelectedList() {
      this.selectedList = [];
      this.isCheckAll = 0;
      this.selecteds = {};
    },
    reRender() {
      this.$forceUpdate();
    },
    search() {
      this.page = 1;

      this.showDrop = false;
      this.reload();
      if (this.searchKey) {
        this.$api
          .newSearchHistory(this.$companyId, {
            userId: this.$user.id,
            keySearch: this.searchKey,
            model: this.nameFunction,
            branchId: this.$branchId,
          })
          .then(() => {
            this.getSearchHistory();
          });
      }
    },
    reload() {
      this.getData(this.page, this.limit, this.sort);
    },
    onChangePage(page) {
      if (this.filterLocal) {
        this.page = page;
        this.onChangeQuery();
        return;
      }
      const oldQuery = this.$route.query || {};
      this.$router.push({
        path: this.pageUrl,
        query: { ...oldQuery, page: page, limit: this.limit },
      });
    },
    changeLimit() {
      localStorage.setItem("config_limit", this.limit);
      if (this.filterLocal) {
        this.onChangeQuery();
        return;
      }

      const oldQuery = this.$route.query || {};
      this.$router.push({
        path: this.pageUrl,
        query: { ...oldQuery, page: this.page, limit: this.limit },
      });
    },

    getSearchHistory() {
      this.$api
        .getSearchHistory(
          {
            "users.id": this.$user.id,
            "branches.id": this.$branchId,
            companyId: this.$companyId,
            model: this.nameFunction,
          },
          4,
          0,
          "searchTime_desc"
        )
        .then((res) => {
          this.oldKeySearchs = res.list;
        });
    },
    async getData(page, limit, sort, isReturn) {
      this.getConfig();

      this.loading = true;
      let where = {};
      if (this.paramsQuery) {
        where = JSON.parse(this.paramsQuery);
      }

      var haveFullText = false;
      if (this.advancedSearch) {
        where.AND = [];
        Object.keys(this.searchObj).map((keys) => {
          if (this.searchObj[keys] || this.searchObj[keys] === 0) {
            if (keys.split(",").length > 1) {
              var or = { OR: [] };
              keys.split(",").map((key) => {
                or.OR.push({ [key]: this.searchObj[keys] });
              });
              where.AND.push(or);
            } else {
              where.AND.push({ [keys]: this.searchObj[keys] });
            }
          } else {
            this.searchObj[keys] = undefined;
          }
        });
        if (where.AND.length == 0) {
          where.AND = undefined;
        }
      } else if (this.searchKey) {
        var searchKey = this.searchKey;
        where.OR = [];
        if (searchKey[0] == "/") {
          this.haveFullText = true;
          if (this.exactCode) {
            where.OR.push({
              [this.exactCode + "_ilike"]: searchKey.substring(1),
            });
          } else {
            where.OR.push({
              code_ilike: searchKey.substring(1),
            });
          }
        } else {
          this.fields.map((item) => {
            if (item.searchKey) {
              if (Array.isArray(item.searchKey)) {
                item.searchKey.map((i) => {
                  where.OR.push({ [i]: searchKey });
                });
              } else {
                where.OR.push({ [item.searchKey]: searchKey });
              }
            }
          });
        }
      }
      let skip = (page - 1) * limit;
      skip = skip < 0 ? 0 : skip;
      try {
        this.$emit("changeFilter", where);
        const res = await this.$api[this.nameFunction](
          where,
          limit,
          skip,
          haveFullText ? "" : sort,
          this.includeInactive || false
        );
        this.loading = false;
        if (isReturn) {
          return res.list;
        }
        this.dataSource = res.list || [];
        if (this.formatData) {
          this.dataSource = this.formatData(this.dataSource);
        }
        this.total = res.totalCount;
        this.totalPage = Math.ceil(this.total / limit);
        this.totalPage = this.totalPage || 1;
        if (this.page > this.totalPage) this.page = this.totalPage;
        this.onSelected();
        this.onScroll();
      } catch (e) {
        this.loading = false;
      }
    },
    changeSort(key) {
      let type = "";
      const [sortKey, sortType] = this.sort.split("_");
      if (sortKey === key) {
        type = sortType === "desc" ? "asc" : "desc";
      } else {
        type = "desc";
      }

      if (this.filterLocal) {
        this.sort = [key, type].join("_");
        this.onChangeQuery();
        return;
      }

      const oldQuery = this.$route.query || {};
      this.$router.push({
        path: this.pageUrl,
        query: {
          ...oldQuery,
          page: this.page,
          limit: this.limit,
          sort: [key, type].join("_"),
        },
      });
    },
    onChangeQuery() {
      if (this.filterLocal) {
        this.getData(this.page, this.limit, this.sort);
        return;
      }
      this.page = this.$route.query.page || 1;
      this.limit = this.$route.query.limit || 25;
      this.sort = this.$route.query.sort || this.$props.sortMode || "id_desc";
      this.getData(this.page, this.limit, this.sort);
    },
  },
  mounted() {
    if (this.filterLocal) {
      this.getData(this.page, this.limit, this.sort);
    } else {
      this.page = this.$route.query.page || 1;
      this.limit = localStorage.getItem("config_limit") || 25;
      this.limit = this.$route.query.limit || this.limit;
      this.sort = this.$route.query.sort || this.$props.sortMode || "id_desc";
      this.getData(this.page, this.limit, this.sort);
    }

    this.getSearchHistory();

    this.advancedSearch = localStorage.getItem("advancedSearch") == "TRUE";
  },
  watch: {
    advancedSearch() {
      localStorage.setItem("advancedSearch", this.advancedSearch ? "TRUE" : "");
    },
    "$route.query.page"() {
      this.onChangeQuery();
    },
    "$route.query.limit"() {
      this.onChangeQuery();
    },
    "$route.query.sort"() {
      this.onChangeQuery();
    },
    nameFunction() {
      this.onChangeQuery();
    },
    paramsQuery() {
      // this.page = 1;
      this.getData(this.page, this.limit, this.sort);
      this.selecteds = {};
    },
  },
};
</script>
