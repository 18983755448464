<template>
  <form @submit.prevent="onSubmitFinance()" class="overflow-auto">
    <div class="box-headline">
      <p class="font-14 bold">Thông tin phiếu</p>
    </div>
    <div class="box-item d-flex gap-1">
      <div class="flex-1">
        <label>Tổng</label>
        <money-input
          class="form-control text-right"
          v-model="financeObj.totalPayment"
          placeholder="Tổng thanh toán"
          :disabled="true"
        />
        <p
          class="text-danger font-12"
          v-if="
            financeObj.totalPayment !=
            financeObj.paymentReqs.reduce((a, b) => a + +b.totalPayment, 0)
          "
        >
          Thông tin hạch toán không khớp
        </p>
      </div>
      <div class="flex-1">
        <label>Loại phiếu</label>
        <select
          class="form-control"
          v-model="financeObj.accountantType"
          placeholder="Loại phiếu"
          :disabled="true"
        >
          <option value="INCOME">Phiếu thu</option>
          <option value="EXPENSE">Phiếu chi</option>
        </select>
      </div>
    </div>

    <div class="box-item d-flex gap-1">
      <div class="flex-2">
        <label>Yếu tố thu / chi</label>

        <auto-complete
          v-if="
            $financeCodes.filter((a) => a.accountantType == type).length > 0
          "
          :list="$financeCodes.filter((a) => a.accountantType == type)"
          v-model="financeObj.elementCode"
          @input="
            financeObj.elementName = $financeCodes.find(
              (a) => a.id == financeObj.elementCode
            ).name
          "
          placeholder="Yếu tố thu / chi"
          class="w-100"
        >
          <div slot="item" slot-scope="{ item }">
            <label>{{ item.code }}</label>
            <p>{{ item.name }}</p>
          </div>
        </auto-complete>

        <input
          v-else
          v-model="financeObj.elementName"
          @input="financeObj.elementCode = financeObj.elementName"
          placeholder="Yếu tố thu / chi"
          class="form-control"
        />
      </div>
      <div class="flex-1">
        <label>Quyển số</label>
        <input
          class="form-control"
          v-model="financeObj.bookNo"
          placeholder="Quyển số"
        />
      </div>
      <div class="flex-1">
        <label>Số phiếu</label>
        <input
          class="form-control"
          v-model="financeObj.codeNumber"
          placeholder="Số phiếu"
        />
      </div>
    </div>

    <div class="box-item d-flex gap-1">
      <div class="flex-1">
        <label>Người nhận đại diên</label>

        <input
          class="form-control"
          required
          v-model="financeObj.receiverRepresent"
          key="free"
          placeholder="Nhập tên người nhận"
        />
      </div>

      <div class="flex-1">
        <label>Người nộp đại diên</label>

        <input
          required
          class="form-control"
          v-model="financeObj.transferRepresent"
          key="free"
          placeholder="Nhập tên người nộp"
        />
      </div>
    </div>

    <div class="d-flex box-item gap-1">
      <div class="flex-2">
        <label> Thông tin / Địa chỉ pháp nhân </label>
        <input
          required
          class="form-control"
          v-model="financeObj.address"
          placeholder="Thông tin / Địa chỉ pháp nhân "
        />
      </div>
      <div class="flex-1">
        <label class="">Ngày giao dịch</label>
        <date-picker
          required
          class="w-100"
          v-model="financeObj.startDate"
          placeholder="Ngày giao dịch"
        />
      </div>
    </div>
    <div class="box-item">
      <label>Diễn dải</label>
      <textarea
        rows="3"
        class="form-control"
        v-model="financeObj.description"
      />
    </div>

    <div>
      <div class="box-headline d-flex align-items-center">
        <div class="flex-fill">
          <p class="bold">Thông tin hạch toán</p>

          <p
            class="text-danger font-12"
            v-if="
              financeObj.totalPayment !=
              financeObj.paymentReqs.reduce((a, b) => a + +b.totalPayment, 0)
            "
          >
            Thông tin hạch toán không khớp
          </p>
        </div>

        <a @click="addPayment()" class="text-primary">+ Thêm mới</a>
      </div>
      <div
        class="box-item d-md-flex align-items-end"
        v-for="(payment, index) in financeObj.paymentReqs"
        :key="index"
      >
        <i
          v-if="financeObj.paymentReqs.length > 1"
          @click="
            financeObj.paymentReqs = financeObj.paymentReqs.filter(
              (i, idx) => idx != index
            )
          "
          class="fa fa-trash pointer text-danger pr-2 font-22"
        />

        <div class="flex flex-1">
          <label>ĐTPN nhận (nợ)</label>
          <div class="d-flex w-100">
            <input
              class="form-control box-100"
              required
              v-model="payment.receiverCode"
              placeholder="Nhập Mã"
            />

            <input
              class="form-control flex-1 flex"
              required
              v-model="payment.receiver"
              placeholder="Nhập tên"
            />
          </div>

          <auto-complete
            class="w-100 mt-1"
            v-model="payment.debtAccId"
            :required="true"
            position="top"
            placeholder="Chọn tài khoản"
            :list="$accountList"
          >
            <div slot="item" slot-scope="{ item }">
              <p>{{ item.name }}</p>
            </div>
          </auto-complete>
        </div>
        <i class="mdi mdi-arrow-left mx-2 font-18" />
        <div class="flex flex-1">
          <label>ĐTPN nộp (có)</label>
          <div class="d-flex w-100">
            <input
              class="form-control box-100"
              required
              v-model="payment.transferCode"
              placeholder="Nhập mã"
            />

            <input
              class="form-control flex-1 flex"
              required
              v-model="payment.transfer"
              placeholder="Nhập tên"
            />
          </div>
          <auto-complete
            v-model="payment.availableAccId"
            position="top"
            :list="$accountList"
            class="w-100 mt-1"
            :required="true"
            placeholder="Chọn tài khoản"
          >
            <div slot="item" slot-scope="{ item }">
              <p>{{ item.name }}</p>
            </div>
          </auto-complete>
        </div>

        <div class="flex flex-10px" />
        <money-input
          required
          v-model="payment.totalPayment"
          class="flex flex-150px form-control text-right"
        />
      </div>

      <p
        class="p-2 bold"
        v-if="
          financeObj.paymentDetailReqs && financeObj.paymentDetailReqs.length
        "
      >
        Thông tin thanh toán
      </p>

      <div
        class="box-item d-md-flex align-items-center"
        v-for="(p, index) in financeObj.paymentDetailReqs"
        :key="'payment_' + index"
      >
        <div class="d-flex align-items-center mb-2">
          <select class="custom-select flex flex-1" v-model="p.type" disabled>
            <option v-for="p in $paymentTypes" :key="p.id" :value="p.id">
              {{ p.name }}
            </option>
          </select>
        </div>
        <div class="d-md-flex flex-fill d-none" />
        <div class="d-flex align-items-center mb-2">
          <div
            class="flex flex-30px"
            v-if="financeObj.paymentDetailReqs.length > 1"
          />
          <money-input
            disabled
            class="form-control flex flex-2 text-right"
            v-model="p.totalCash"
          />
          <select
            v-model="p.currencyUnit"
            disabled
            class="flex flex-1 form-control"
          >
            <option
              :value="exchange.currency"
              :key="exchange.id"
              v-for="exchange in $exchangeList"
            >
              {{ exchange.currency }}
              ({{ $formatMoney(exchange.exchangePrice) }})
            </option>
          </select>
        </div>
      </div>
    </div>
    <button type="submit" ref="btnFinance " class="hide">OK</button>
  </form>
</template>

<script>
export default {
  props: ["total", "type", "value"],
  created() {
    this.financeObj = {
      ...this.financeObj,
      ...this.value,
    };
  },
  watch: {
    initData() {
      this.financeObj = {
        ...this.financeObj,
        ...this.value,
      };
    },
    financeObj: {
      deep: true,
      handler() {
        this.$emit("input", this.financeObj);
      },
    },
  },
  data() {
    return {
      financeObj: {
        startDate: new Date(),
        paymentReqs: [
          {
            debtAccId: "",
            availableAccId: "",
            totalPayment: 0,
            transferType: "",
            receiverType: "",
            transfer: "",
            receiver: "",
            receiverId: "",
            transferId: "",
            receiverCode: "",
            transferCode: "",
          },
        ],
      },
    };
  },
  methods: {
    addPayment() {
      this.financeObj.paymentReqs.push({
        debtAccId: "",
        availableAccId: "",
        totalPayment: 0,
        transferType: "",
        receiverType: "",
        transfer: "",
        receiver: "",
        receiverId: "",
        transferId: "",
        receiverCode: "",
        transferCode: "",
      });
    },
  },
};
</script>

<style>
</style>