import VueRouter from "vue-router";
import { MENU, MENU_SALE } from "@/config/BranchConfig";

import Order from "./pages/order";
import Sale from "./pages/Sale";
const Login = () => import("./pages/Login");
const Admin = () => import("./pages/admin");
const Stock = () => import("./pages/stock");
const Report = () => import("./pages/report");
const Work = () => import("./pages/work");

const pageName = process.env.VUE_APP_PAGENAME || "GratiPos";

let priorities = MENU.filter((i) => !i.title).map((i) => ({
  link: i.link,
  permission: i.permission,
}));

MENU_SALE.map((i) => {
  priorities.push({ link: i.url, permission: i.permission });
  if (i.children) {
    i.children.map((c) => {
      priorities.push({ link: c.url, permission: c.permission });
    });
  }
});

const routes = [
  {
    path: "/login",
    component: Login,
    meta: { title: pageName + " | Đăng nhập" },
  },
  // {
  //   path: "/setup",
  //   component: () => import("./pages/setup"),
  //   meta: { title: pageName + " | Thiết lập" },
  // },

  {
    path: "/profile",
    component: Sale,
    children: [
      {
        path: "",
        component: () => import("./pages/profile"),
        children: [
          {
            path: "",
            component: () => import("./pages/profile/Info"),
          },
          {
            path: "password",
            component: () => import("./pages/profile/Password"),
          },
        ],
        meta: {
          title: pageName + " | Profile",
        },
      },
    ],
  },
  {
    path: "/customer/:id",
    component: () => import("./pages/admin/customer/Detail"),
    meta: {
      title: pageName + " | Khách hàng",
      roles: "",
    },
  },
  {
    path: "/work",
    component: Sale,
    meta: { roles: "WORK" },
    children: [
      {
        path: "",
        component: Work,
        meta: { roles: "WORK" },
        children: [
          { path: "", redirect: () => "all" },
          {
            path: "all",
            component: () => import("./pages/work/All"),
            meta: { title: pageName + " | Sản xuất", roles: "WORK:VIEW" },
          },
          {
            path: "track",
            component: () => import("./pages/work/Track"),
            meta: { title: pageName + " | Sản xuất", roles: "WORK:VIEW" },
          },
          {
            path: "new",
            component: () => import("./pages/work/New"),
            meta: { title: pageName + " | Sản xuất", roles: "WORK:NEW" },
          },
          {
            path: "report",
            component: () => import("./pages/work/Report"),
            meta: { title: pageName + " | Sản xuất", roles: "WORK:REPORT" },
          },
          {
            path: ":id",
            component: () => import("./pages/work/Detail"),
            meta: {
              title: pageName + " | Sản xuất",
              roles: "WORK:VIEW,WORK:NEW",
            },
          },
        ],
      },
    ],
  },
  // {
  //   path: "/",
  //   component: Sale,
  //   children: [
  //     {
  //       path: "",
  //       component: () => import("./pages/profile"),
  //     },
  //   ],
  //   meta: {
  //     title: pageName + " | Profile",
  //   },
  // },
  {
    path: "/order",
    component: Sale,
    meta: { roles: "ORDER" },
    children: [
      {
        path: "",
        component: Order,
        meta: { roles: "ORDER" },
        children: [
          {
            path: "",
            component: () => import("./pages/order/all"),
            meta: {
              roles: "ORDER:ALL",
              title: pageName + " | Tất cả đơn",
            },
          },
          {
            path: "cart",
            component: () => import("./pages/order/cart"),
            meta: {
              title: pageName + " | Đặt hàng",
              roles: "ORDER:CART",
            },
          },
          {
            path: "cart/:id",
            component: () => import("./pages/order/cart/Detail"),
            meta: {
              title: pageName + " | Chi tiết đặt hàng",
              roles: "ORDER:CART",
            },
          },
          {
            path: "pos",
            component: () => import("./pages/order/pos/New"),
            meta: {
              roles: "ORDER:NEW",
              title: pageName + " | Tạo đơn POS mới",
            },
          },
          {
            path: "sale",
            component: () => import("./pages/order/sale/New"),
            meta: {
              roles: "ORDER:B2B",
              title: pageName + " | Tạo đơn hàng mới",
            },
          },
          {
            path: "track",
            component: () => import("./pages/order/Track"),
            meta: {
              title: pageName + " | Theo dõi",
              roles: "ORDER:TRACK",
            },
          },

          {
            path: "refund",
            component: () => import("./pages/order/refund"),
            meta: {
              roles: "ORDER:REFUND",
              title: pageName + " | Trả hàng",
            },
          },

          {
            path: ":id",
            component: () => import("./pages/order/sale/Detail"),
            meta: {
              roles: "ORDER:NEW,ORDER:ALL,ORDER:B2B",
              title: pageName + " | Thông tin đơn",
            },
          },
          {
            path: ":id/edit",
            component: () => import("./pages/order/sale/New"),
            meta: {
              roles: "ORDER:NEW,ORDER:ALL,ORDER:B2B",
              title: pageName + " | Sửa đơn",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/stock",
    component: Sale,
    meta: { roles: "STOCK", title: pageName + " | Kho" },
    children: [
      {
        path: "",
        component: Stock,
        meta: { roles: "STOCK" },
        children: [
          {
            path: "",
            component: () => import("./pages/stock/menu.vue"),
            meta: { roles: "STOCK", title: pageName + " | Quản lý kho" },
          },
          {
            path: "purchase",
            component: () => import("./pages/stock/purchase/StockPurchase"),
            meta: { roles: "STOCK:PURCHASE", title: pageName + " | Đơn mua" },
          },
          {
            path: "purchase/new",
            component: () => import("./pages/stock/purchase/NewPurchase"),
            meta: { roles: "STOCK:PURCHASE", title: pageName + " | Đơn mua" },
          },
          {
            path: "purchase/:id",
            component: () => import("./pages/stock/purchase/DetailPurchase"),
            meta: { roles: "STOCK:PURCHASE", title: pageName + " | Đơn mua" },
          },
          {
            path: "purchase/:poId/receive",
            component: () => import("./pages/stock/in/NewIn"),
            meta: { roles: "STOCK:PURCHASE", title: pageName + " | Đơn mua" },
          },
          {
            path: "purchase/:poId/edit",
            component: () => import("./pages/stock/purchase/NewPurchase"),
            meta: { roles: "STOCK:PURCHASE", title: pageName + " | Đơn mua" },
          },
          {
            path: "in",
            component: () => import("./pages/stock/in/StockIn"),
            meta: { roles: "STOCK:IN", title: pageName + " | Nhập kho" },
          },
          {
            path: "in/new",
            component: () => import("./pages/stock/in/NewIn"),
            meta: { roles: "STOCK:IN", title: pageName + " | Nhập kho" },
          },
          {
            path: "in/import",
            component: () => import("./pages/stock/in/ImportIn"),
            meta: { roles: "STOCK:IN", title: pageName + " | Nhập kho" },
          },
          {
            path: "out",
            component: () => import("./pages/stock/out/StockOut"),
            meta: { roles: "STOCK:OUT", title: pageName + " | Xuất kho" },
          },
          {
            path: "out/new",
            component: () => import("./pages/stock/out/NewOut"),
            meta: { roles: "STOCK:OUT", title: pageName + " | Xuất kho" },
          },
          {
            path: "out/import",
            component: () => import("./pages/stock/out/ImportOut"),
            meta: { roles: "STOCK:OUT", title: pageName + " | Xuất kho" },
          },
          {
            path: "state",
            component: () => import("./pages/stock/state/index"),
            meta: { roles: "STOCK:STATE", title: pageName + " | Tồn kho" },
            children: [
              { path: "", redirect: "stock" },
              {
                path: "stock",
                component: () => import("./pages/stock/state/StockState"),
              },
              {
                path: "product",
                component: () => import("./pages/stock/state/StockCheck"),
              },
              {
                path: "tag",
                component: () => import("./pages/stock/state/StockTag"),
              },
              {
                path: "tag/:id",
                component: () => import("./pages/stock/state/StockTag"),
              },
              {
                path: "min",
                component: () => import("./pages/stock/state/StockMin"),
              },
              {
                path: "out",
                component: () => import("./pages/stock/state/StockOut"),
              },
            ],
          },
          {
            path: "transfer",
            component: () => import("./pages/stock/transfer/StockTransfer"),
            meta: {
              roles: "STOCK:TRANSFER",
              title: pageName + " | Danh sách chuyển kho",
            },
          },
          {
            path: "transfer/import",
            component: () => import("./pages/stock/transfer/ImportTransfer"),
            meta: {
              roles: "STOCK:TRANSFER",
              title: pageName + " | Nhập chuyển kho",
            },
          },
          {
            path: "transfer/new",
            component: () => import("./pages/stock/transfer/NewTransfer"),
            meta: {
              roles: "STOCK:TRANSFER",
              title: pageName + " | Tạo chuyển kho",
            },
          },
          {
            path: "transfer/:id",
            component: () => import("./pages/stock/transfer/DetailTransfer"),
            meta: {
              roles: "STOCK:TRANSFER",
              title: pageName + " | Thông tin chuyển kho",
            },
          },
          // {
          //   path: "transfer/:id/receive",
          //   component: () => import("./pages/stock/transfer/ReceiveTransfer"),
          //   meta: { roles: "STOCK:TRANSFER", title: pageName + " | Nhận kho" },
          // },

          {
            path: "asset",
            component: () => import("./pages/stock/asset/StockAsset"),
            meta: { roles: "STOCK:ASSET", title: pageName + " | Tài sản" },
          },
          {
            path: "asset/new",
            component: () => import("./pages/stock/asset/NewAsset"),
            meta: { roles: "STOCK:ASSET", title: pageName + " |  Tài sản" },
          },
          {
            path: "asset/import",
            component: () => import("./pages/stock/asset/ImportAsset"),
            meta: { roles: "STOCK:ASSET", title: pageName + " |  Tài sản" },
          },
          {
            path: "asset/:id",
            component: () => import("./pages/stock/asset/DetailAsset"),
            meta: { roles: "STOCK:ASSET", title: pageName + " |  Tài sản" },
          },
        ],
      },
    ],
  },
  {
    path: "/401",
    component: Sale,
    children: [
      {
        path: "",
        component: () => import("./pages/401"),
        meta: {
          title: pageName + " | Không có quyền",
        },
      },
    ],
  },

  {
    path: "/report",
    component: Sale,
    meta: { roles: "REPORT", title: pageName + " | Báo cáo chi nhánh" },
    children: [
      {
        path: "",
        component: Report,
        meta: { roles: "REPORT" },
        children: [
          // { path: "", redirect: () => getNextPage("REPORT") },
          {
            path: "revenue",
            component: () => import("./pages/report/Revenue"),
            meta: { roles: "REPORT:REVENUE" },
          },
          {
            path: "supplier",
            component: () => import("./pages/report/Supplier"),
            meta: { roles: "REPORT:SUPPLIER" },
          },
          {
            path: "product",
            component: () => import("./pages/report/Product"),
            meta: { roles: "REPORT:PRODUCT" },
          },
          {
            path: "shift-sales",
            component: () => import("./pages/report/ShiftSales"),
            meta: { roles: "REPORT:SHIFTSALE" },
          },
          {
            path: "employee",
            component: () => import("./pages/report/Employee"),
            meta: { roles: "REPORT" },
          },
          // {
          //   path: "work",
          //   component: () => import("./pages/report/Work"),
          //   meta: { roles: "REPORT" },
          // },
        ],
      },
    ],
  },
  {
    path: "/finance",
    component: Sale,
    meta: { roles: "FINANCE", title: pageName + " |  Hạch toán" },
    children: [
      {
        path: "",
        component: () => import("./pages/finance/index"),
        meta: { roles: "FINANCE" },
        children: [
          {
            path: "",
            component: () => import("./pages/finance/All"),
            meta: { roles: "FINANCE" },
          },
          {
            path: "summary",
            component: () => import("./pages/finance/Summary"),
            meta: { roles: "FINANCE" },
          },
        ],
      },
    ],
  },
  {
    path: "/admin",
    component: Admin,
    meta: { roles: "ADMIN" },
    children: [
      // { path: "", redirect: () => getNextPage("ADMIN") },
      {
        path: "board",
        component: () => import("./pages/admin/Home/index.vue"),
        meta: {
          title: pageName + " | Home",
          roles: "ADMIN",
        },
      },

      {
        path: "customer",
        component: () => import("./pages/admin/customer/All"),
        meta: {
          title: pageName + " | Khách hàng",
          roles: "ADMIN:CUSTOMER",
        },
      },
      // {
      //   path: "customer/update",
      //   component: () => import("./pages/admin/customer/Update"),
      //   meta: {
      //     title: pageName + " |  Sửa nhiều khách hàng",
      //     roles: "ADMIN:CUSTOMER",
      //   },
      // },

      {
        path: "customer/credit",
        component: () => import("./pages/admin/customer/ImportCredit"),
        meta: {
          title: pageName + " | Nhập dư nợ khách hàng",
          roles: "ADMIN:CUSTOMER",
        },
      },

      {
        path: "org-customer",
        component: () => import("./pages/admin/org-customer/All"),
        meta: {
          title: pageName + " | Công ty khách hàng",
          roles: "ADMIN:CUSTOMER",
        },
      },

      {
        path: "org-customer/:id",
        component: () => import("./pages/admin/org-customer/Detail"),
        meta: {
          title: pageName + " | Công ty khách hàng",
          roles: "ADMIN:CUSTOMER",
        },
      },

      {
        path: "potential",
        component: () => import("./pages/admin/potential/All"),
        meta: {
          title: pageName + " | KH tiềm năng",
          roles: "ADMIN:CUSTOMER",
        },
      },

      {
        path: "potential/:id",
        component: () => import("./pages/admin/potential/Detail"),
        meta: {
          title: pageName + " | KH tiềm năng | Chi tiết",
          roles: "ADMIN:CUSTOMER",
        },
      },

      {
        path: "group-customer",
        component: () => import("./pages/admin/group-customer/All"),
        meta: {
          title: pageName + " | Nhóm khách hàng",
          roles: "ADMIN:CUSTOMER",
        },
      },
      {
        path: "stock",
        component: () => import("./pages/admin/stock/All"),
        meta: {
          title: pageName + " | Kho",
          roles: "ADMIN:STOCK",
        },
      },

      {
        path: "supplier",
        component: () => import("./pages/admin/supplier/All"),
        meta: {
          title: pageName + " | Nhà cung cấp",
          roles: "ADMIN:SUPPLIER",
        },
      },

      {
        path: "supplier/credit",
        component: () => import("./pages/admin/supplier/ImportCredit"),
        meta: {
          title: pageName + " | Nhập dư nợ NCC",
          roles: "ADMIN:SUPPLIER",
        },
      },

      {
        path: "supplier/:id",
        component: () => import("./pages/admin/supplier/Detail"),
        meta: {
          title: pageName + " | Chi tiết nhà cung cấp",
          roles: "ADMIN:SUPPLIER",
        },
      },
      {
        path: "finance",
        component: () => import("./pages/admin/finance/All"),
        meta: {
          title: pageName + " | Hạch toán - thu chi",
          roles: "ADMIN:FINANCE",
        },
      },
      {
        path: "customer/import",
        component: () => import("./pages/admin/customer/Import"),
        meta: {
          title: pageName + " | Khách hàng",
          roles: "ADMIN:CUSTOMER",
        },
      },
      {
        path: "customer/:id",
        component: () => import("./pages/admin/customer/Detail"),
        meta: {
          title: pageName + " | Chi tiết khách hàng",
          roles: "ADMIN:CUSTOMER",
        },
      },
      {
        path: "promo",
        component: () => import("./pages/admin/Promo"),
        meta: {
          title: pageName + " | Mã khuyến mãi",
          roles: "ADMIN:PROMO",
        },
      },
      {
        path: "campaign",
        component: () => import("./pages/admin/campaign/All"),
        meta: {
          title: pageName + " | Campaign",
          roles: "ADMIN:PROMO",
        },
      },
      {
        path: "campaign/new",
        component: () => import("./pages/admin/campaign/New"),
        meta: {
          title: pageName + " | New Campaign",
          roles: "ADMIN:PROMO",
        },
      },

      {
        path: "member",
        component: () => import("./pages/admin/Member"),
        meta: {
          title: pageName + " | Loại thành viên",
          roles: "ADMIN:CUSTOMER",
        },
      },
      {
        path: "category",
        component: () => import("./pages/admin/Category"),
        meta: {
          title: pageName + " | Nhóm hàng",
          roles: "ADMIN:PRODUCT",
        },
      },
      {
        path: "order",
        component: () => import("./pages/admin/order/All"),
        meta: {
          title: pageName + " | Bán hàng",
          roles: "ADMIN:ORDER",
        },
      },
      {
        path: "order/:id",
        component: () => import("./pages/admin/order/Detail"),
        meta: {
          title: pageName + " | Chi tiết đơn hàng",
          roles: "ADMIN:ORDER",
        },
      },
      // {
      //   path: 'refund', component: () => import('./pages/admin/Refund'), meta: {
      //     title: 'GratiPos | Refund',
      //     roles: 'ADMIN:ORDER'
      //   }
      // },
      {
        path: "product/update-multi",
        component: () => import("./pages/admin/product/UpdateMulti"),
        meta: {
          title: pageName + " | Update Multi Product",
          roles: "ADMIN:PRODUCT",
        },
      },

      {
        path: "product/new",
        component: () => import("./pages/admin/product/New"),
        meta: {
          title: pageName + " | Sản phẩm mới",
          roles: "ADMIN:PRODUCT",
        },
      },
      {
        path: "product/import",
        component: () => import("./pages/admin/product/Import"),
        meta: {
          title: pageName + " | Nhập sản phẩm",
          roles: "ADMIN:PRODUCT",
        },
      },
      {
        path: "variant",
        component: () => import("./pages/admin/variant/All"),
        meta: {
          title: pageName + " | Bộ sản phấm",
          roles: "ADMIN:PRODUCT",
        },
      },
      {
        path: "variant/:id",
        component: () => import("./pages/admin/variant/Edit"),
        meta: {
          title: pageName + " | Bộ sản phấm",
          roles: "ADMIN:PRODUCT",
        },
      },
      {
        path: "product",
        component: () => import("./pages/admin/product/All"),
        meta: {
          title: pageName + " | Tất cả sản phẩm",
          roles: "ADMIN:PRODUCT",
        },
      },

      {
        path: "template",
        component: () => import("./pages/admin/template/All"),
        meta: {
          title: pageName + " | Tất cả mẫu đơn",
          roles: "ADMIN:ORDER_TEMPLATE",
        },
      },

      {
        path: "template/:id",
        component: () => import("./pages/admin/template/Detail"),
        meta: {
          title: pageName + " | Chi tiết mẫu đơn",
          roles: "ADMIN:ORDER_TEMPLATE",
        },
      },

      {
        path: "product/:id",
        component: () => import("./pages/admin/product/Edit"),
        meta: {
          title: pageName + " | Chi tiết sản phẩm",
          roles: "ADMIN:PRODUCT",
        },
      },
      {
        path: "report",
        component: () => import("./pages/admin/report"),
        meta: {
          roles: "ADMIN:REPORT",
          title: pageName + " | Báo cáo công ty",
        },
        children: [
          { path: "", redirect: "summary" },
          {
            path: "summary",
            component: () => import("./pages/admin/report/SummaryReport"),
          },
          {
            path: "revenue",
            component: () => import("./pages/admin/report/RevenueReport"),
          },
          {
            path: "finance",
            component: () => import("./pages/admin/report/FinanceReport"),
          },
          {
            path: "work",
            component: () => import("./pages/admin/report/WorkReport"),
          },
          // {
          //   path: "current-stock",
          //   component: () => import("./pages/admin/report/CurrentStock"),
          // },
          {
            path: "stock",
            component: () => import("./pages/admin/report/StockReport"),
          },

          {
            path: "debt",
            component: () => import("./pages/admin/report/DebtReport"),
          },

          {
            path: "other",
            component: () => import("./pages/admin/report/OtherReport"),
          },
        ],
      },
      {
        path: "target",
        component: () => import("./pages/admin/target/index.vue"),
        meta: {
          title: pageName + " | Mục tiêu",
          roles: "ADMIN:REPORT",
        },
      },
      // {
      //   path: "target/new",
      //   component: () => import("./pages/admin/target/New.vue"),
      //   meta: {
      //     title: pageName + " | Thêm mục tiêu mới",
      //     roles: "ADMIN:REPORT",
      //   },
      // },
      {
        path: "company",
        component: () => import("./pages/admin/Company"),
        meta: {
          title: pageName + " | Công ty",
          roles: "ADMIN:COMPANY",
        },
      },
      {
        path: "branch",
        component: () => import("./pages/admin/branch"),
        meta: {
          title: pageName + " | Chi nhánh",
          roles: "ADMIN:BRANCH",
        },
      },
      {
        path: "branch/:id",
        component: () => import("./pages/admin/branch"),
        meta: {
          title: pageName + " | Chi nhánh",
          roles: "ADMIN:BRANCH",
        },
      },
      {
        path: "store",
        component: () => import("./pages/admin/Store"),
        meta: {
          title: pageName + " | Web bán",
          roles: "ADMIN:BRANCH",
        },
      },
      {
        path: "user",
        component: () => import("./pages/admin/User"),
        meta: {
          title: pageName + " | Người dùng",
          roles: "ADMIN:USER",
        },
      },
      {
        path: "employee",
        component: () => import("./pages/admin/employee/All"),
        meta: {
          title: pageName + " | Nhân viên",
          roles: "ADMIN:EMPLOYEE",
        },
      },
      {
        path: "employee/:id",
        component: () => import("./pages/admin/employee/detail/index.vue"),
        meta: {
          title: pageName + " | Nhân viên",
          roles: "ADMIN:EMPLOYEE",
        },
      },
      {
        path: "department",
        component: () => import("./pages/admin/department/All"),
        meta: {
          title: pageName + " | Phòng ban",
          roles: "ADMIN:DEPARTMENT",
        },
      },

      {
        path: "user/:id",
        component: () => import("./pages/admin/UserEdit"),
        meta: {
          title: pageName + " | Chi tiết người dùng",
          roles: "ADMIN:USER",
        },
      },
      {
        path: "permission",
        component: () => import("./pages/admin/Permission"),
        meta: {
          title: pageName + " | Phân quyền",
          roles: "ADMIN:USER",
        },
      },

      {
        path: "**",
        component: () => import("./pages/admin/404"),
        meta: {
          title: pageName + " | 404",
        },
      },
    ],
  },

  {
    path: "/**",
    component: Sale,
    children: [
      {
        path: "",
        component: () => import("./pages/404"),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  var token = localStorage.getItem("token");
  var isSupperAdmin = localStorage.getItem("isSupperAdmin");

  let roles = localStorage.getItem("currentRoles");

  if (roles && token) {
    roles = JSON.parse(roles);
    if (to.path == "/" || to.path == "/login") {
      const pages = roles.map((i) => i.fxCode).sort((a, b) => (b > a ? -1 : 1));

      var page = priorities.find((i) => pages.indexOf(i.permission) > -1);
      if (page) {
        next(page.link);
        return;
      }
      next("/401");
      return;
    }
    const rolesAccepts = (to.meta.roles || "").split(",") || "";

    let isAllow = false;
    if (isSupperAdmin !== "true") {
      roles.map((role) => {
        rolesAccepts.map((a) => {
          if (role.fxCode && role.fxCode.indexOf(a) == 0) {
            isAllow = true;
          }
        });
      });
    } else {
      isAllow = true;
    }
    if (!isAllow) {
      next("/401");
      return;
    }
  }
  if (!token && to.path !== "/login") {
    // var returnURl = to.query.return_url;
    // if (returnURl) {
    //   next("/login?return_url=" + returnURl);
    // } else {
    next("/login");
    // }
    return;
  }

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        var value = tagDef[key];
        if (key == "title") {
          value = pageName + " | " + value;
        }
        tag.setAttribute(key, value);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  if (from.query.token && !to.query.token) {
    if (to.path === from.path) {
      return;
    }
    next({ path: to.path, query: { token: from.query.token } });
  }

  next();
});

export default router;
